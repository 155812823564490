#cws-infographic-accordion, #cws-infographic-bubble-diagram {
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    .panel-body {
        line-height: 1.2;
        p:first-child {
            font-size: 20px;
        }
        ul {
            padding-left: 18px;
        }
        blockquote {
            border: none;
            padding: 15px;
            font-size: 18px;
            margin-top: 40px;
            margin-bottom: 0;
            em {
                font-style: normal;
            }
        }
    }
    .teal {
        .panel-body {
            p:first-child {
                color: @teal;
            }
            blockquote {
                background-color: fade(@teal, 20%);
                em {
                    color: @teal;
                }
            }
        }
    }
    .yellow {
        .panel-body {
            p:first-child {
            }
            blockquote {
                background-color: fade(@yellow, 20%);
                em {
                }
            }
        }
    }
    .mandarin {
        .panel-body {
            p:first-child {
                color: @mandarin;
            }
            blockquote {
                background-color: fade(@mandarin, 20%);
                em {
                    color: @mandarin;
                }
            }
        }
    }
    .pink {
        .panel-body {
            p:first-child {
                color: @pink;
            }
            blockquote {
                background-color: fade(@pink, 20%);
                em {
                    color: @pink;
                }
            }
        }
    }
    .grape {
        .panel-body {
            p:first-child {
                color: @grape;
            }
            blockquote {
                background-color: fade(@grape, 20%);
                em {
                    color: @grape;
                }
            }
        }
    }
    .blue {
        .panel-body {
            p:first-child {
                color: @blue;
            }
            blockquote {
                background-color: fade(@blue, 20%);
                em {
                    color: @blue;
                }
            }
        }
    }
    .green {
        .panel-body {
            blockquote {
                background-color: fade(@green, 20%);
                em {
                    color: @green;
                }
            }
        }
    }
    .primary-blue {
        .panel-body {
            p:first-child {
                color: @brand-blue-primary;
            }
            blockquote {
                background-color: fade(@brand-blue-primary, 20%);
                em {
                    color: @brand-blue-primary;
                }
            }
        }
    }
}
#cws-infographic-accordion {
    padding: 0;
    @media screen and (min-width: @screen-md) {
        display: none;
    }
    .accordion-section-heading {
        color: @white;
        background-color: @brand-blue-primary-light;
        text-align: center;
        padding: 30px 15px 20px;
        width: 100%;
        box-shadow: 0 3px 3px 0 fade(@gray, 60%);
        position: relative;
        h3, p {
            font-weight: 600;
        }
        h3 {
            font-size: 36px;
            margin-bottom: 0;
        }
        p {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    #cws-infographic {
        .panel + .panel {
            margin-top: 0;
        }
        .panel {
            .panel-heading {
                padding: 8px 15px 5px;
                a {
                    color: @white;
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    .icon {
                        height: 60px;
                        width: 100px;
                        margin-right: 15px;
                        fill: currentColor;
                    }
                    &:after {
                        content: "\f067";
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 30px;
                        font-weight: 300;
                    }
                }
                a[aria-expanded=true] {
                    &:after {
                        content: "\f068";
                    }
                }
            }
            .panel-body {
                padding-top: 35px;
                padding-bottom: 25px;
                p:first-child {
                    margin-bottom: 30px;
                }
            }
            &.teal {
                .panel-heading {
                    background-color: @teal;
                }
            }
            &.yellow {
                .panel-heading {
                    background-color: @yellow;
                }
            }
            &.mandarin {
                .panel-heading {
                    background-color: @mandarin;
                }
            }
            &.pink {
                .panel-heading {
                    background-color: @pink;
                }
            }
            &.grape {
                .panel-heading {
                    background-color: @grape;
                }
            }
            &.blue {
                .panel-heading {
                    background-color: @blue;
                }
            }
            &.primary-blue {
                .panel-heading {
                    background-color: @brand-blue-primary;
                }
            }
            &.green {
                .panel-heading {
                    background-color: @green;
                }
            }
        }
    }
}
#cws-infographic-bubble-diagram {
    font-family: @font-family-brand;
    align-items: center;
    display: none;
    @media screen and (min-width: @screen-md) {
        display: flex;
    }
    .diagram {
        flex: 0 0 auto;
        position: relative;
        width: 485px;
        height: 725px;
        margin-right: 4rem;
        @media screen and (min-width: 1200px) {
            margin-left: 7rem;
            margin-right: 5rem;
        }
        .circle, .line {
            position: absolute;
        }
        .circle {
            display: none;
            color: @white;
            border-radius: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 2;
            h3, h4, p {
                font-weight: 600;
                margin: 0;
                line-height: 1.1;
            }
            h3 {
                font-size: 4rem;
            }
            p {
                max-width: 145px;
            }
            &.loaded {
                display: flex;
            }
            &-main {
                background: @brand-blue-primary;
                animation: createCircle .5s;
                width: 27rem; 
                height: 27rem;
                top: 50%;
                transform: translateY(-50%);
                right: 30/485*100%;
            }
            &-secondary {
                cursor: pointer;
                animation: createCircle .5s;
                width: 13rem; 
                height: 13rem;
                transition: transform .5s ease;
                .icon {
                    height: 55px;
                    width: 90px;
                    fill: @white;
                }
                &.label-updated, &.label-new {
                    &:before {
                        width: 8rem;
                        height: 3.44rem;
                        position: absolute;
                        top: -1.2rem;
                        content: "";
                        z-index: 5;
                    }
                }
                &.label-updated {
                    &:before {
                        background: url('/images/label-updated.svg') no-repeat center;
                        left: 4%;
                    }
                }
                &.label-new {
                    &:before {
                        background: url('/images/label-new.svg') no-repeat center;
                        left: 2%;
                    }
                }
                &.active {
                    transform: scale(1.1);
                    position: relative;
                    &:after {
                        width: 14rem;
                        height: 14rem;
                        content: "";
                        border: 1px solid;
                        border-radius: 50%;
                        position: absolute;
                    }
                }
                &:nth-child(1) {
                    background-color: @pink;
                    top: 65px;
                    left: 370/485*100%;
                    &.active {
                        &:after {
                            border-color: @pink;
                        }
                    }
                }
                &:nth-child(2) {
                    background-color: @teal;
                    top: 52px;
                    left: 215/485*100%;
                    &.active {
                        &:after {
                            border-color: @teal;
                        }
                    }
                }
                &:nth-child(3) {
                    background-color: @yellow;
                    top: 120px;
                    left: 70/485*100%;
                    &.active {
                        &:after {
                            border-color: @yellow;
                        }
                    }
                }
                &:nth-child(4) {
                    background-color: @mandarin;
                    top: 230px;
                    left: -48/485*100%;
                    &.active {
                        &:after {
                            border-color: @mandarin;
                        }
                    }
                }
                &:nth-child(5) {
                    background-color: @brand-blue-primary;
                    top: 380px;
                    left: -48/485*100%;
                    &.active {
                        &:after {
                            border-color: @brand-blue-primary;
                        }
                    }
                }
                &:nth-child(6) {
                    background-color: @grape;
                    top: 490px;
                    left: 70/485*100%;
                    &.active {
                        &:after {
                            border-color: @grape;
                        }
                    }
                }
                &:nth-child(7) {
                    background-color: @blue;
                    top: 574px;
                    left: 215/485*100%;
                    &.active {
                        &:after {
                            border-color: @blue;
                        }
                    }
                }
                &:nth-child(8) {
                    background-color: @green;
                    top: 560px;
                    left: 370/485*100%;
                    &.active {
                        &:after {
                            border-color: @green;
                        }
                    }
                }
            }
        }
        .line {
            display: none;
            height: 0;
            border-bottom: 3px dotted @grey_2;
            transform-origin: left bottom;
            top: 50%;
            left: 300/485*100%;
            z-index: 1;
            &.loaded {
                display: block;
                animation: createLine 1s;
                animation-fill-mode: forwards;
            }
            &:nth-child(2) {
                transform: rotate(-63deg);
            }
            &:nth-child(3) {
                transform: rotate(-100deg);
            }
            &:nth-child(4) {
                transform: rotate(-137deg);
            }
            &:nth-child(5) {
                transform: rotate(-168deg);
            }
            &:nth-child(6) {
                transform: rotate(-194deg);
            }
            &:nth-child(7) {
                transform: rotate(-226deg);
            }
            &:nth-child(8) {
                transform: rotate(94deg);
            }
            &:nth-child(9) {
                transform: rotate(62deg);
            }
        }
        @keyframes createCircle {
            from {
                transform: scale(0);
            }
            to {
                transform: scale(1);
            }
        }
        @keyframes createLine {
            from {
                width: 0;
            }
            to {
                width: 230px;
            }
        }
    }
    .swiper {
        flex: 1 1 0;
        .panel-heading {
            font-size: 30px;
            font-weight: 600;
            margin: 0;
            padding-top: 25px;
        }
        .panel-body {
            padding-bottom: 25px;
            blockquote {
                border-top: 1px solid @grey_2;
            }
        }
        .teal {
            border: 1px solid @teal;
            .panel-heading {
                color: @teal;
            }
        }
        .yellow {
            border: 1px solid @yellow;
            .panel-heading {
                color: @yellow;
            }
        }
        .mandarin {
            border: 1px solid @mandarin;
            .panel-heading {
                color: @mandarin;
            }
        }
        .pink {
            border: 1px solid @pink;
            .panel-heading {
                color: @pink;
            }
        }
        .grape {
            border: 1px solid @grape;
            .panel-heading {
                color: @grape;
            }
        }
        .blue {
            border: 1px solid @blue;
            .panel-heading {
                color: @blue;
            }
        }
        .primary-blue {
            border: 1px solid @brand-blue-primary;
            .panel-heading {
                color: @brand-blue-primary;
            }
        }
        .green {
            border: 1px solid @green;
            .panel-heading {
                color: @green;
            }
        }
    }
}