.hospital-page-masthead {
    @media screen and (max-width: 991.98px) {
        display: flex;
        flex-direction: column;
    }
    .hospital-title {
        h1, .h1 {
            margin-top: 2rem;
            margin-bottom: 2.5rem;
            @media screen and (min-width: 992px) {
                margin-top: 3.5rem;
                margin-bottom: 3rem;
            }
        }
    }
    .hospital_info {
        z-index: 2;
        position: relative;
        background-color: @white;
        @media screen and (max-width: 991.98px) {
            order: 2;
        }
        &.main_h_view {
            background-color: @white-transparent2;
        }
        p {
            margin-bottom: .5rem;
        }
        a {
            &:hover, &:active, &:focus {
                text-decoration: none;
            }
        }
        strong {
            font-weight: 600;
        }
    
        .v_hours, .map_parking {
            line-height: 1.25;
            display: flex;
            column-gap: 1rem;
            align-items: center;
            margin-bottom: .5rem;
        }
        .map_parking {
            span {
                color: @gray;
                position: relative;
                padding-right: 2rem;
                &:after {
                    content: '▼';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 0;
                    display: block;
                    font-size: smaller;
                    color: @brand-blue-primary;
                }
            }
        }
        .extra_data {
            line-height: 2rem;
        }
        @media screen and (max-width: 991.8px) {
            .v_hours, .map_parking {
                flex: 0 0 100%;
            }
            .v_hours {
                line-height: 24px;
            }
            .map_parking {
                line-height: 22px;
                margin-top: .8rem;
            }
            .extra_data {
                font-size: 16px;
                line-height: 20px;
                flex: 0 0 100%;
            }
        }
    }
    .hospital-hours-map-bar {
        @media (min-width: 992px) {
            column-gap: 2.8rem;
        }
    }
    .v_hours {
        padding-right: 0;
        @media (max-width: 991.98px) {
            flex: 0 0 100%;
        }
    }
    .hours-dropdown {
        position: relative;
        display: inline-block;
        .hours-select {
            background: none;
            border: none;
            font-size: inherit;
            color: inherit;
            appearance: none; /* Remove default select styling */
            cursor: pointer;
            padding: 0 2rem 0 0;
            &:focus {
                outline: none;
            }
        }
        .dropdown-arrow {
            position: relative;
            margin-bottom: 0;
            font-weight: 400;
            &:after {
                content: '▼';
                position: absolute;
                z-index: -1;
                right: 0;
                display: block;
                top: 0;
                font-size: smaller;
                color: @brand-blue-primary;
            }

        }
    }

    #hospital-page-header-image {
        @media screen and (max-width: 991.98px) {
            order: 1;
        }
    }

    .link_line {
        @media screen and (max-width: 991.98px) {
            order: 3;
        }
        @media screen and (max-width: 768.98px) {
            margin-bottom: 0;
        }
    }
}

.hospital-page-section {
    padding: 2.7rem 0;
    @media screen and (min-width: 600px) {
        padding: 2.7rem 0 5.7rem 0;
    }
    @media (min-width: 992px) {
        padding: 10rem 0;
    }
    .page-title {
        h2 {
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
    }
    h3 {
        margin-top: 0;
    }
    .flex_hor {
        column-gap: 1.6rem;
        @media screen and (min-width: 992px) {
            column-gap: 2.8rem;
        }
        @media screen and (max-width: 991.98px) {
            padding: 0;
        }
        .item {
            margin: 0 0 3.6rem 0;
            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (min-width: 600px) {
                display: flex;
                flex-direction: column;
                row-gap: 3.3rem;
                flex: 0 0 calc(50% - 0.8rem)!important;
                margin: 0;
                justify-content: space-between;
            }
            @media screen and (min-width: 992px) {
                flex: 0 0 calc(50% - 1.4rem)!important;
            }
            .img_part {
                padding-bottom: 0!important;
                max-height: 100%!important;
                margin-bottom: 1.8rem;
                @media screen and (min-width: 600px) {
                    margin-bottom: 0;
                }
                @media screen and (min-width: 768px) {
                    flex: 0 0 auto;
                }
                .inner {
                    position: static;
                    height: 34.8rem;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .text_part {
                @media screen and (min-width: 768px) {
                    flex: 1 1 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                }
            }
        }
        &.num-col-3 {
            .item {
                @media screen and (min-width: 992px) {
                    flex: 0 0 calc(33.33% - 1.4rem)!important;
                }
            }
        }
    }

    .news_preview {
        .num-col-3 {
            row-gap: 2rem;
            @media screen and (min-width: 992px) {
                row-gap: 0;
            }
        }
    }

    .imp_text {
        margin-bottom: 3rem;
        @media screen and (min-width: 992px) {
            margin-bottom: 5rem;
        }
        h2 {
            margin-bottom: 2rem;
        }
        p {
            font-size: 1.6rem;
            line-height: 1.375;
        }
    }

    .section-heading {
        margin-bottom: 2.6rem;
        @media screen and (min-width: 992px) {
            margin-bottom: 5rem;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 1.5rem;
            letter-spacing: -0.4px;
        }
    }

    .split-block {
        & > div {
            margin-bottom: 4.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (min-width: 992px) {
                margin-bottom: 0;
            }
        }
        h3 {
            margin-bottom: 2.6rem;
        }
    }

    .text-block-space-left, .text-block-space-right {
        @media screen and (min-width: 768px) {
            width: 80.7%;
        }
    }
    .text-block-space-left {
        @media screen and (min-width: 768px) {
            margin: 0 0 0 auto;
        }
    }
    .text-block-space-right {
        @media screen and (min-width: 768px) {
            margin: 0 auto 0 0;
        }
    }

    .hosp_specialties {
        gap: 2rem;
        margin: 0 1.5rem;
        @media (min-width: 992px) {
            column-gap: 2.4rem;
            row-gap: 2.5rem;
            max-width: 94rem;
            margin: 0 auto;
            width: 100%;
        }
        .spec_item {
            flex: 0 0 100%;
            cursor: pointer;
            @media (min-width: 992px) {
                flex: 0 0 ~"calc(50% - 1.4rem)";
            }
            .inner {
                color: @brand-blue-primary;
                font-size: 2rem;
                font-weight: 700;
                line-height: 1;
                padding: 1.3rem 1.5rem 1.2rem;
                border: 1.5px solid @brand-blue-primary;
                background-color: @white;
                &:hover, &:active {
                    color: @brand-blue-primary-light;
                    border-color: @brand-blue-primary-light;
                }
            }
            @media screen and (max-width: @screen-xs) {
                width: 100%;
            }
        }
    }

    .item-with-image {
        column-gap: 2rem;
        row-gap: 3rem;
        align-items: baseline;
        @media (min-width: 600px) {
            align-items: stretch;
        }
        @media (min-width: 992px) {
            column-gap: 2.8rem;
            row-gap: 5rem;
        }
        .item {
            flex: 0 0 100%;
            flex-direction: column;
            @media (min-width: 600px) {
                flex: 0 0 ~"calc(50% - 1rem)";
            }
            @media (min-width: 992px) {
                flex-direction: row;
                flex: 0 0 ~"calc(50% - 1.4rem)";
            }
            .item-img {
                flex: 0 0 auto;
                width: 100%;
                height: 0;
                padding-bottom: 48.61%;
                position: relative;
                @media (min-width: 600px) {
                    height: 17.5rem;
                    padding-bottom: 0;
                }
                @media (min-width: 992px) {
                    width: 46.76%;
                    height: 24.7rem;
                    order: 2;
                }
                img {
                    position: absolute;
                }
                &.no-img {
                    background-color: @grey_2;
                    @media (min-width: 992px) {
                        width: 26rem;
                        height: 24.7rem;
                    }
                }
            }
            .item-content {
                flex: 1 1 0;
                background-color: @white;
                padding: 1.7rem 2.8rem 1.7rem 2.1rem;
                @media (min-width: 768px) {
                    order: 1;
                    height: 24.7rem;
                }
                h3 {
                    margin-top: 0;
                }
                .desc {
                    font-size: 1.6rem;
                    line-height: 1.375;
                }
            }
        }
    }

    .content_blocks_container {
        .item {
            .text_part {
                h3 {
                }
            }
        }
    }

    &#hospital-welcome-section {
        .manager-profile {
            flex: 0 0 100%;
            max-width: 36.2rem;
            img {
                width: 100%;
                display: block;
                margin-bottom: 2rem;
            }
            @media (min-width: 992px) {
                flex: 0 0 31.84%;
                margin-right: 10.7%;
            }
        }
        .section-copy {
            flex: 1 1 100%;
            margin-bottom: 5rem;
            @media (min-width: 992px) {
                flex: 1 1 0;
                margin-bottom: 0;
            }
            .highlighted {
                font-size: 2rem;
                line-height: 1;
                .text-blue;
            }
            .signature {
                display: block;
                height: 5rem;
            }
        }
    }
    &.paediatrics {
        background: url('/images/paediatrics-mobile-bg.png') no-repeat center 0;
        background-size: 48.3rem auto;
        position: relative;
        @media screen and (min-width: 480px) {
            background-size: cover;
            background-position: center 6%;
        }
        @media screen and (min-width: 600px) {
            background-position: center -10vw;
        }
        @media screen and (min-width: 720px) {
            height: 72.7rem;
            background-position: center center;
            background-image: url('/images/paediatrics-bg.png');
        }
        .main-text {
            min-height: 85rem;
            @media screen and (min-width: 480px) {
                min-height: 100rem;
            }
            @media screen and (min-width: 600px) {
                min-height: 150vw;
            }
            @media screen and (min-width: 720px) {
                min-height: auto;
            }
            h2 {
                font-size: 2.8rem;
                text-align: center;
                line-height: 1;
                @media screen and (min-width: 720px) {
                    font-size: 4rem;
                    text-align: left;
                }
            }
            p {
                text-align: center;
                max-width: 34.9rem;
                margin: 0 auto;
                @media screen and (min-width: 600px) {
                    max-width: 40rem;
                }
                @media screen and (min-width: 720px) {
                    max-width: 52rem;
                    font-size: 1.8rem;
                    text-align: left;
                    margin: 0;
                }
            }
            .text-md-left {
                @media screen and (min-width: 720px) {
                    text-align: left;
                }
            }
            .line_link {
                max-width: 23.2rem;
                margin: 0 auto;
                @media screen and (min-width: 600px) {
                    max-width: 100%;
                }
                & + .small {
                    display: block;
                    margin-top: 2rem;
                    font-weight: 700;
                    // @media screen and (min-width: 1200px) {
                    //     display: inline-block;
                    //     margin-left: 2rem;
                    // }
                }
            }
        }
        .bottom-text {
            background: #015a82;
            color: @white;
            padding: 2rem;
            position: absolute;
            bottom: 0;
            width: 100%;
            @media screen and (min-width: 600px) {
                padding: 3.3rem;
            }
            @media screen and (min-width: 720px) {
                width: 100%;
                min-height: 12rem;
                background: none;
                bottom: 4rem;
            }
            p {
                text-align: center;
                max-width: 32rem;
                margin: 0 auto;
                @media screen and (min-width: 600px) {
                    max-width: 60rem;
                }
                @media screen and (min-width: 720px) {
                    max-width: 87rem;
                }
                a {
                    color: @white;
                }
            }
        }
    }
}

.hospital-page-contact.vg_form {
    background: #d9e9f6!important;
    .inner {
        margin: 5rem 1.5rem 2rem 1.5rem;
        @media screen and (min-width: 992px) {
            margin: 9rem auto;
        }
        .section-heading {
            margin-bottom: 2.5rem;
            @media screen and (min-width: 992px) {
                margin-bottom: 5rem;
            }
            h2 {
                line-height: 1.25;
            }
        }
        .right_part {
            padding: 0;
            background-color: transparent;
            & > div {
                h2 {
                    margin-top: 0;
                    margin-bottom: 1.5rem;
                    @media screen and (min-width: 600px) {
                        white-space: nowrap;
                        text-align: center;
                    }
                }
                form {
                    max-width: 55.6rem;
                    margin-left: auto;
                    margin-right: auto;
                    .field {
                        display: block;
                        border: none;
                        margin-bottom: 1.8rem;
                        &.paragraph {
                            margin-bottom: 3.2rem;
                        }
                        .input-group-addon {
                            background-color: transparent;
                            padding: 0;
                            margin-bottom: .3rem;
                            line-height: 1.25;
                        }
                        .form-control {
                            display: block;
                            float: none;
                        }
                        #fields-message {
                            height: 18.6rem;
                        }
                    }
                    .input-group {
                        border: none;
                        button.btn-primary {
                            padding: .6rem 4rem;
                        }
                    }
                }
            }
        }
    }
}

.special-parking-map-heading {
    margin: 0;
    font-weight: 700;
    font-family: @font-family-base!important;
    padding: 1.5rem 3rem;
}