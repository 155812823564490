.figure {
  position: relative;
}

.figure-caption-icon {
  [class*=" circle-icon-"],
  [class^="circle-icon-"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}