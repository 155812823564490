.spacing-generator(@abbrev, @prop, @size) when (@size >= 0) {
  .@{abbrev}-t-@{size} {
    @{prop}-top: @spacer * @size;
  }

  .@{abbrev}-b-@{size} {
    @{prop}-bottom: @spacer * @size;
  }

  .@{abbrev}-r-@{size} {
    @{prop}-right: @spacer * @size;
  }

  .@{abbrev}-l-@{size} {
    @{prop}-left: @spacer * @size;
  }

  .@{abbrev}-x-@{size} {
    @{prop}-right: @spacer * @size;
    @{prop}-left: @spacer * @size;
  }

  .@{abbrev}-y-@{size} {
    @{prop}-top: @spacer * @size;
    @{prop}-bottom: @spacer * @size;
  }

  .spacing-generator(@abbrev, @prop, (@size - 1));
}


.spacing-generator(m, margin, 6);
.spacing-generator(p, padding, 6);