.block-facts {
  .flex_hor_3;
  padding: 60px 0;
  .fact-item {
    text-align: center;
    padding: 0 3rem;
    .int {
      font-size: 76px;
      line-height: .7;
      font-weight: 600;
      margin-bottom: 5px;
    }
    font-family: @font-family-brand;
    .text {
      font-size: 21px;
    }

    &:nth-child(1) {
      color: @orange;
    }

    &:nth-child(2) {
      color: @pink;
    }

    &:nth-child(3) {
      color: @teal;
    }

    .btn {
      width: 180px;
      margin-top: 30px;
    }
    small {
      font-size: 58%;
      vertical-align: top;
    }
  }
  justify-content: space-around;
  align-items: flex-end;
  @media screen and (max-width: @screen-sm-max) {
    .fact-item {
      padding: 0 1rem;
      .int {
        font-size: 50px;
      }
      &:nth-child(2) {
        margin: 0 1rem;
        padding: 0 2rem;
      }
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    align-items: center;
    padding: 30px 10px;
    .fact-item {
      padding: 2rem 0;
    }
  }
}

