.patient-admission-form {
    &-notes {
        margin-bottom: 3.5rem;
        .block-mandarin {
            border-width: 2px;
            .block-heading {
                padding: 2rem;
            }
            .block-body {
                background-color: @white;
                padding: 2rem;
                ul {
                    padding-left: 1.5rem;
                }
            }
        }
    }
    .row {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        .col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .form-control, p, label {
        @media screen and (max-width: 991.98px) {
            font-size: 1.5rem;
        }
    }
    input.form-control {
        padding: 0;
    }
    .control-label {
        display: block;
    }
}