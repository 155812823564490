.block-search {
  padding: 25px 0;
  background-color: @teal;
  h6 {
    .font_brand;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0;
  }
  .input-group-addon {
    background-color: @white;
    .icon-search {
      color: @grey_2;
    }
  }
  .form-control, .select2-container--default .select2-selection--single {
    height: 46px;
    border-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        line-height: 46px;
      }
    }
    .select2-selection__placeholder {
      color: @gray;
    }
  }

  &.orange_blue {
    padding: 0 0;
    .two_colors(@orange, @brand-blue-primary, .6, false);
    .tc_part {
      padding: 30px 15px;
      &:first-child {
        background-color: @orange;
        padding: 35px 25px 35px 15px
      }
      &:last-child {
        background-color: @brand-blue-primary;
        color: @white;
        padding-left: 25px;
        p {
          margin-bottom: 0;
        }
        font-family: @font-family-brand;
        min-height: 100px;
        font-weight: 500;
        font-size: 20px;
        position: relative;
        b {
          font-weight: 600;
        }
        a {
          color: @white;
          font-weight: 500;
          .fa {
            margin-right: 10px;
          }
        }
      }
    }

  }
  .inner {
    .flex-display(flex, row, nowrap,flex-end,center,center);
    h6 {
      flex: 1 0 auto;
    }
    .for_select {
      flex: 1 1 auto;
    }
    select.form-control {
      min-width: 250px;
    }
    @media screen and (max-width: @screen-md-max) {
      flex-wrap: wrap;
      h6, .for_select {
        margin-bottom: 10px;
      }
      .select2 {
        max-width: 250px;
      }
    }
    @media screen and (max-width: @screen-sm-max) {
      h6, .for_select {
        flex-basis: 50%;
      }
      h6 {
        text-align: right;
        padding-right: 30px;
      }
    }
    @media screen and (max-width: @screen-xs) {
      h6, .for_select {
        flex-basis: 100%;
        width: 100%;
      }
      h6 {
        text-align: left;
        padding-right: 0;
      }
      .select2 {
        width: 100%!important;
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: @screen-md-max) {
    padding: 25px 15px;
    .container {
      width: 100%;
    }
    h6 {
      margin-bottom: 10px;
    }
  }
}
.select2-rendered__match {
  color: @brand-blue-primary;
  font-size: 20px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: @gray-light;
  color: @text-color;
}

[id^="page-"] {
  display: none;
}
