.btn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-secondary {
  .button-variant(@btn-secondary-color, @btn-secondary-bg, @btn-secondary-border);
}

.btn-secondary-light {
  .button-variant(@btn-secondary-light-color, @btn-secondary-light-bg, @btn-secondary-border);
}


.btn-w-140 {
  width: 140px;
}

button.with-icon {
	span {
		display: inline-block;
		&.text {margin: 0 10px;}
		&.icon {display: none;}
	}

	&.submitting {
		.icon {display: inline-block;}	
	}

	&:disabled {
		cursor: default;
		opacity: 1 !important;
	}
}