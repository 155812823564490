.media-vertical {
  display: block;

  .media-image {
    margin-bottom: 15px;
  }

  .media-body {
    display: block;
    width: auto;
  }

  .media-title {
    margin: 0 0 20px;
    font-size: 20px;
    font-family: @font-family-brand;
    font-weight: 500;
    a {
      color: @text-color;

      &:focus,
      &:hover {
        color: darken(@text-color, 5%);
        text-decoration: none;
      }
    }
  }

  p {
    margin: 20px 0;
  }
}