.forms-modal {
    .modal-dialog {
        @media screen and (min-width: @screen-md-min) {
            width: 960px;
        }
        @media screen and (min-width: @screen-lg-min) {
            width: 1200px;
        }
        .modal-body {
            padding: 50px 30px 30px;
            @media screen and (min-width: @screen-md-min) {
                padding: 50px 50px 30px;
            }
            a {
                text-decoration: underline;
            }
            h3, h4 {
                letter-spacing: 0.03em;
            }
            .forms-and-leaflets {
                h4 {
                    font-family: omnes-pro,sans-serif;
                    font-weight: 500;
                    font-size: 20px;
                    margin-top: 0;
                }
                & > p {
                    margin-bottom: 20px;
                }
                .about_forms {
                    & > div {
                        a {
                            text-decoration: none;
                            &:hover, &:active {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}