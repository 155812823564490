.jumbotron {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & + & {
    margin-top: -30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h1, h2, h3, h4 {
    margin: 30px 0;
    font-size: @font-size-h2;
  }

  .btn:last-child {
    margin-bottom: 30px;
  }

  &.bg-image-cover,
  &.bg-blue-primary,
  &.bg-blue-primary-light,
  &.bg-blue-secondary,
  &.bg-blue-secondary-light {
    color: #FFF;
  }
}

.jumbotron-billboard {
  height: 240px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.jumbotron-video {
  padding: 60px 0;
  margin-bottom: 0;

  .jumbotron + & {
    margin-top: -30px;
  }

  .video-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 747px; // This width may need adjustments if dynamic sizing is needed in responsive design.
    max-width: 100%;
    height: 0;
    margin: 0 auto;
    font-size: @font-size-large;
    padding-top: 56.25%;
    font-weight: 700;
    text-align: center;
    color: @white;
    cursor: pointer;
    background-color: fade(@grape, 80%);
    position: relative;
    background-size: cover; // Ensures background properties are retained
    background-position: center center;

    .icon-play {
      font-size: 120px; // Default font size
    }

    .video-placeholder-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 2.6rem;
      @media (min-width: 992px) {
        row-gap: 4.8rem;
        .icon-play {
          font-size: 12.2rem;
        }
      }
    }
  }

  .video-container {
    width: 100%;
    max-width: 74.8rem;
    margin: 0 auto;
  }

  .for_video {
    display: none;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    &.shown {
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  .text_line {
    font-family: @font-family-brand;
    font-weight: 500; // Default weight
    font-size: 4rem;
    line-height: 5rem;
    margin: 30px 0;
    letter-spacing: 0.5px;
  }

  .imp_text {
    .text_line {
      margin: 0;
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 1.25;
      @media (min-width: 768px) {
        font-size: 4rem;
        line-height: 5rem;
      }
    }
  }

  @media screen and (max-width: @screen-xs-max) {
    padding: 30px 0;

    .video-placeholder {
      .icon-play {
        font-size: 6.4rem;
      }
    }

    &.shown {
      i, br, .text_line {
        display: none;
      }
      .for_video {
        width: 100%;
        position: relative;
        height: auto;
      }
    }

    .text_line {
      font-size: 2.8rem;
      line-height: 1.25;
    }
  }
}