.bg-image-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-blue-primary {
  background-color: @brand-blue-primary !important;
}

.bg-blue-primary-light {
  background-color: @brand-blue-primary-light !important;
}

.bg-blue-secondary {
  background-color: @brand-blue-secondary !important;
}

.bg-blue-secondary-light {
  background-color: @brand-blue-secondary-light !important;
}

.bg-light-blue {
  background-color: #D9E9F6;
}

.bg-light-teal {
  background-color: #eff8f9;
}

.bg-light-gray {
  background-color: @brand-gray-light;
}