
.global-header {
  &:extend(.container);
  height: 96px;
  padding-top: 18px;

  .southercross-logo {
    float: left;
  }

  .toplink {
    display: inline-block;
    padding-left: 20px;
    line-height: 30px;
    text-decoration: none;
    outline: none!important;
    .icon-search {
      padding-left: 10px;
      vertical-align: sub;
      font-size: 26px;
    }
    &.active {
      color: fade(@brand-blue-primary, 60%);
    }
    &.text-orange.active {
      color: fade(@orange, 60%);
    }
  }
  @media (max-width: @screen-sm-max) {
    .toplink {
      padding-left: 0;
      .icon-search {
        font-size: 22px;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    height: 85px;
    padding-top: 12px;
  }
  @media screen and (max-width: 380px) {
    height: 6rem;
  }
}


//
// Global Navbar
//

.global-navbar {
  background-color: @brand-primary;
  height: 60px;
  .container {
    position: relative;
    padding: 0;
  }

  @media (max-width: @screen-sm-max) {
    height: 50px;
    .search_toggle {
      color: @white;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 15;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 380px) {
    height: 4rem;
    .search_toggle {
      top: .8rem;
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    &:before,
    &:after {
      display: none;
    }

    & > li {
      & > a {
        padding: 20px 0;
        line-height: 20px;
        color: #FFF;
        font-weight: bold;
        outline: none!important;
        &:focus,
        &:hover {
          color: rgba(255,255,255,.85);
          background: transparent;
        }
        @media (min-width: @screen-md-min) {
          white-space: nowrap;
        }
      }

      &.active > a {
        &,
        &:focus,
        &:hover {
          color: #A8D2EB;
        }
      }

      &.hidden-xs.hidden-sm {
        @media (min-width: @screen-md-min) {
          flex: 1 1 100%;
        }
      }

    }

    @media (min-width: @screen-md-min) {
      height: auto !important;
      justify-content: flex-start;
      column-gap: 4.5rem;
    }
    @media (min-width: @screen-lg-min) {
      column-gap: 6.5rem;
    }

    @media (max-width: @screen-sm-max) {
      flex-direction: column;
      background-color: #FFF;
      box-shadow: 0 4px 20px rgba(0,0,0,.15);
      &.collapsing {
        z-index: 10;
      }
      &.collapse {
        display: none;

        &.in {
          display: block;
          z-index: 10;
        }
      }

      & > li {
        & + li {
          border-top: 2px solid @hr-border;
        }

        & > a {
          padding: 15px 0;

          &,
          &:focus,
          &:hover {
            color: @text-color;
          }
        }

        &.active > a {
          &,
          &:hover,
          &:focus {
            color: @link-color;
          }
        }
      }
    }
  }

  .navbar-toggle {
    float: none;
    height: 50px;
    margin: 0 15px 0 0;
    padding-left: 25px;
    color: @white;
    @media screen and (max-width: 380px) {
      height: 4rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
    .icon-bars {
      font-size: 27px;
      padding-right: 12px;
      vertical-align: middle;
      @media screen and (max-width: 380px) {
        font-size: 2.3rem;
      }
    }
    .text-node {
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      vertical-align: middle;
      height: 27px;
      @media screen and (max-width: 380px) {
        font-size: 1.5rem;
        line-height: 2.3rem;
        height: 2.3rem;
      }
    }
    @media (max-width: @screen-sm-max) {
      display: block;
    }
  }
}


//
// Global Container
//

.global-container {
  //&:extend(.container);
  //flex-grow: 1;
}


//
// Global Footer
//
.global-footer-disclaimer {
  margin: 0 0 10px;
}
.global-footer-navbar {
  padding: 50px 0;
  .bg-colour(@gray);

  @media (max-width: @screen-sm-max) {
    display: none;
  }

  .nav-link {
    display: block;
    padding: 3px 0;
    color: #FFF;

    &--button {
      border: none;
      background: transparent;
    }
  }

  .footer-nav-col-1 {
    .nav-link {
      font-weight: 300;
      opacity: .6;
      &:first-child {
        margin-bottom: @spacer*.8;
        opacity: 1;
        font-weight: 500;
      }
    }
  }
  .footer-nav-col-2, .footer-nav-col-3 {
    .nav-link {
      margin-bottom: @spacer*.8;
      opacity: 1;
      font-weight: 500;
    }
  }
  .footer-nav-col-2, .footer-nav-col-3 {
    .nav {
      display: flex;
      flex-wrap: wrap;
      .nav-link {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }
  .footer-nav-col-2 {
    .nav {
      #toitu, #pride-pledge {
        width: 5rem;
      }
      #toitu {
        margin-top: 3.5rem;
      }
      #pride-pledge {
        margin-top: 5rem;
        margin-left: -.5rem;
      }
    }
  }
  .footer-nav-col-3 {
    .nav {
      #shielded-logo, #linkedin {
        width: 3.7rem;
        margin-top: 1.5rem;
      }
      #shielded-logo {
        margin-left: -.5rem;
      }
    }
  }

  .nav-title {
    margin: 0 0 10px;
    font-size: @font-size-base;

    .nav-link {
      color: #FFF;
    }
  }

}

.global-footer-websites {
  min-height: 50px;
  background-color: @brand-primary;

  .container {
    display: flex;
    align-items: center;

    @media (max-width: 1112px) {
      padding: 0 15px;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: @screen-xs-max) {
      padding: 0 10px;
      font-size: 14px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .nav-title {
    width: auto;
    padding: 15px 40px 15px 0;
    color: #FFF;
    font-weight: bold;
    white-space: nowrap;

    &:after {
      content: ':';
    }
    a {
      color: @white;
      text-decoration: none;
      &:hover {

      }
    }
    @media (max-width: 1112px) {
      width: 100%;
      cursor: pointer;
      &:after {
        .fa-icon;
        .fal;
        content: @fa-var-chevron-down;
        display: block;
        vertical-align: middle;
        position: absolute;
        right: 25px;
        top: 17px;
        font-size: 18px;
      }
    }
    &[aria-expanded="true"] {
      @media (max-width: 1112px) {
        &:after {
          content: @fa-var-chevron-up;
        }
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    white-space: nowrap;

    &:after,
    &:before {
      display: none;
    }

    @media (min-width: 1113px) {
      height: auto !important;
    }

    @media (max-width: 1112px) {
      flex-direction: column;

      &.collapse {
        display: none;

        &.in {
          display: flex;
        }
      }
    }

    & > .nav-link {
      display: block;
      padding: 15px 0;
      color: #FFF;
      opacity: .6;
      text-decoration: none;
      @media screen and (max-width: @screen-sm-max) {
        padding: 8px 0;
        i {
          margin-right: 1em;
        }
        &:last-child {
          padding-bottom: 20px;
        }
      }
      @media screen and (max-width: @screen-xs-max) {
        padding: 6px 0;
      }
      &:focus,
      &:hover {
        opacity: .9;
      }
    }
  }
  i {
    font-size: 13px;
    margin-right: 2px;
  }
}


.global-footer-terms {
  font-size: @font-size-small;

  .terms-header {
    background-color: @brand-gray-light;

    a {
      color: @text-color;
      margin-left: 30px;
      @media screen and (max-width: @screen-sm-max) {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .terms-body {
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      font-family: @font-family-brand;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 10px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

#globalNav {
  @media screen and (max-width: @screen-sm-max) {
    position: relative;
    &.collapsing, &.in {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow-y: scroll; /* has to be scroll, not auto */
      z-index: 10;
    }
    &:not(.in) {
      height: 0;
      overflow: hidden;
    }
    padding: 0 0;
    li {
      padding: 0 25px;
      a {
        padding: 10px 0;
        font-size: 18px;
      }
    }
  }
  @media screen and (max-width: 380px) {
    li {
      padding: 0 1rem;
      a {
        padding: .6rem 0;
        font-size: 1.5rem;
      }
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 992px) and (orientation : portrait) {
    .nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 34px;
    }
  }
}

#our-people-intro {
  .container {
    padding-bottom: 0;
  }
}