.breadcrumb {
  padding: 0;
  font-size: @font-size-mini;
  line-height: 23px;
  background: none;
  border-radius: 0;
  background-color: #D7E9F7;
  .clearfix;
  margin-bottom: 0;
  li {
    float: left;
    padding: 8px 5px 2px;
    vertical-align: top;
    white-space: nowrap;
    & > a {
      color: @grey_2;
    }

    &.active {
      > a {
        color: @gray;
        text-decoration: underline;
      }
    }

    &:before {
      display: none !important;
    }

    &:not(.here):not(.back_link)::before {
      display: block !important;
      content: '/';
      color: @gray;
      float: left;
      padding: 0 10px 0 0;
    }
  }

  .here {
    color: @gray;
    padding-left: 10px;
  }

  .back_link {
    background-color: @brand-blue-primary;
    color: @white;
    padding: 4px 16px;
    a {
      color: @white;
      text-decoration: none !important;
      &:hover {
        text-decoration: none;
      }
    }
    .transition(1s, background-color);
    &:hover {
      text-decoration: none;
      background-color: @brand-blue-primary-light;
      color: @white;
    }
    i {
      font-size: 150%;
      line-height: 1.5;
    }
  }
}