.page-masthead {
    &.careers-masthead, &.current-vacancies-masthead {
        @media screen and (max-width: 991px) {
            height: 40rem;
        }
        @media screen and (max-width: 767px) {
            height: 28rem;
        }
        @media screen and (min-width: 992px) {
            height: 450px;
        }
        @media screen and (min-width: 1440px) {
            height: 470px
        }
        picture {
            @media screen and (max-width: 1920px) {
                width: 1920px;
                left: ~"calc((100% - 1920px)/2)";
            }
            @media screen and (max-width: 1440px) {
                width: 1440px;
                left: ~"calc((100% - 1440px)/2)";
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                left: ~"calc((100% - 800px)/2)";
                top: -1rem;
            }
            @media screen and (max-width: 400px) {
                left: 0;
            }
            .object-fit-cover {
                @media screen and (max-width: 400px) {
                    object-position: 59.49% 48.84%!important;
                }
            }
        }
    }
}

#careers-current-vacancies-form {
    .fit_block {
        .text_part:first-child {
            @media screen and (min-width: 768px) {
                padding-right: 0;
            }
        }
    }
}

/* to do: apply consistent h2 styling across the site once design guide is confirmed */
#careers-care-is-at-the-heart-of-all-we-do, #careers-come-join-our-team, #careers-develop-your-career-with-us, #careers-how-we-work, #careers-contact-us, #nurse-entry-to-practice-netp-programme {
    h2 {
        margin-bottom: 3rem;
        @media screen and (max-width: 767.98px) {
            font-size: 2.8rem;
        }
    }
}

#careers-develop-your-career-with-southern-cross-healthcare {
    .for_block_title {
        h1 {
            text-align: center;
            color: @brand-blue-primary;
        }
    }
    .imp_text {
        h4 {
            text-align: center;
            color: @brand-blue-primary;
            font-size: 20px;
        }
    }
    .fit_block {
        font-family: @font-family-brand;
        font-size: 15px;
        font-weight: 500;
    }
}

#why-work-for-us, #careers-contact-us {
    .fit_block {
        padding: 0;
        .text_part {
            padding: 0 15px;
            font-family: @font-family-brand;
            margin-bottom: 25px;
            @media screen and (min-width: 768px) {
                padding: 0 45px;
            }
            & > * {
                max-width: 550px;
            }
            p {
                font-weight: 500;
            }
        }
    }
    .fit_block .text_part + img.width__50 {
        @media screen and (min-width: 768px) {
            width: 50%;
            flex-basis: 50%;
            margin-left: 0;
        }
    }
}

#careers-contact-us {
    .imp_text {
        a {
            text-decoration: underline;
        }
    }
    .fit_block {
        @media screen and (max-width: 1399.98px) {
            align-items: flex-end;
        }
        @media screen and (max-width: 1199.98px) {
            flex-wrap: wrap;
        }
        & > * {
            @media screen and (max-width: 1199.98px) {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
            }
        }
        .text_part {
            @media screen and (max-width: 1399.98px) {
                margin-top: 5rem;
                margin-bottom: 5rem;
            }
            @media screen and (max-width: 1199.98px) {
                order: 1;
                margin-bottom: 0;
            }
        }
        .width__50.hidden-xs {
            @media screen and (max-width: 1199.98px) {
                order: 2;
            }
        }
    }
}

#careers-come-join-our-team {
    @media screen and (min-width: 768px) {
        padding-top: 3.7rem;
        padding-bottom: 5rem;
    }
}

#current-vacancies-filter-form {
    .dropdown {
        position: relative;
        select {
            background-image: none;
        }
        .caret {
            position: absolute;
            color: @gray;
            right: 1rem;
            top: 1.7rem;
        }
    }
}

#careers-develop-your-career-with-us {
    @media screen and (min-width: 768px) {
        padding-top: 6rem;
        padding-bottom: 5rem;
    }
    iframe {
        width: 100%;
        height: 280px;
    }
}

#form-current-vanancies-contact-us {
    margin: 3rem auto 0 auto;
    max-width: 55.6rem;
    .fields {
        flex-wrap: wrap;
        row-gap: 1.5rem;
        column-gap: 1rem;
        margin-bottom: 2rem;
        @media screen and (min-width: 768px) {
            justify-content: center;
        }
        .field {
            flex: 0 1 ~"calc(50% - 5px)";
            &#input-group-subject, &#input-group-message {
                flex: 1 1 100%;
            }
            .input-group-addon {
                display: none;
            }
            textarea {
                height: 10rem;
            }
        }
    }
}

.h3-link {
    font-family: @font-family-brand;
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 0 10px;
}

.jobs-location-filter, .jobs-type-filter {
    min-width: 19rem;
}

.job-meta, .share-buttons, .job-attachment {
    .icon {
        display: inline-block;
        vertical-align: middle;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }
}
.job-meta {
    font-weight: 500;
    @media screen and (max-width: 991.8px) {
        padding-left: 0;
        padding-right: 0;
    }
    .icon {
        width: 25px;
        height: 25px;
        &.icon-reference {
            width: 28px;
            height: 28px;
        }
        &.icon-location {
            margin-left: -5px;
        }
    }
}
.job-details {
    margin-bottom: 40px;
    @media screen and (min-width: 600px) {
        margin-bottom: 50px;
    }
    .job-title-share-buttons, .job-full-description, .job-meta, .actions-container {
        @media screen and (min-width: 992px) {
            margin-left: 35px;
            max-width: 900px;
        }
    }
    .job-full-description {
        margin-top: 2.5rem;
    }
    .job-title {
        color: @brand-blue-primary;
        @media screen and (max-width: 991.8px) {
            font-size: 30px;
            margin-top: 0;
        }
    }
    .job-meta {
        margin-top: 15px;
        margin-bottom: 15px;
        @media screen and (min-width: 768px) {
            align-items: center;
        }
        @media screen and (max-width: 767.8px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
        .icon {
            color: @brand-blue-primary;
        }
        .location, .closing-date, .job-reference {
            span {
                line-height: 2.5rem;
                vertical-align: top;
            }
        }
        .job-apply-button {
            @media screen and (max-width: 767.8px) {
                margin-top: 1rem;
            }
        }
    }
    .job-title-share-buttons {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .job-title {
            line-height: 1;
            margin-bottom: 0;
        }
        .share-buttons {
            margin-bottom: 10px;
            @media screen and (min-width: 992px) {
                position: absolute;
                right: 0;
                bottom: 10px;
                margin-bottom: 0;
            }
            .icon {
                width: 28px;
                height: 28px;
            }
        }
    }
    .job-attachment {
        margin-top: 20px;
        .icon {
            width: 28px;
            height: 28px;
        }
    }
    .actions-container {
        display: inline-flex;
        flex-direction: column;
        margin-top: 30px;
        .h3-link {
            margin-bottom: 0;
            display: inline-block;
        }
    }
    .job-apply-button {
        font-weight: 500;
        width: 150px;
    }
}

.filter-form-banner {
    row-gap: 20px;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 3rem;
    padding: 0;
    .job-filter-form, .jobs-banner {
        flex: 0 0 100%;
        padding: 0 1rem;
    }
    .job-filter-form {
        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2rem;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
            .form-inline {
                @media screen and (min-width: 768px) {
                    flex: 1 1 auto;
                }
                label {
                    display: block;
                }
                .form-control {
                    width: 100%;
                    max-width: 40rem;
                    border: 1px solid @grey_2;
                }
                &:first-child {
                    @media screen and (min-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    label {
                        @media screen and (min-width: 768px) {
                            text-align: left;
                            max-width: 40rem;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .jobs-banner {
        a {
            display: block;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 15%;
            }
        }
    }
}

.jobs {
    row-gap: 20px;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 35px;
    font-family: @font-family-brand;
    padding: 0;
    .job {
        padding: 0 10px;
        flex: 0 0 100%;
        @media screen and (min-width: 768px) {
            flex: 0 1 50%;
        }
        .job-title {
            font-weight: 600;
            font-size: 22px;
            margin-top: 0;
            margin-bottom: 25px;
        }
        .job-meta, .job-short-description {
            font-size: 15px;
        }
        .job-meta {
            margin-bottom: 10px;
            max-width: 400px;
            @media screen and (max-width: 991.8px) {
                flex-direction: column;
                flex-wrap: nowrap;
            }
            @media screen and (max-width: 767.8px) {
                flex-direction: row;
                flex-wrap: wrap;
            }
            @media screen and (max-width: 599.8px) {
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }
        .link-more {
            font-weight: 500;
        }
        .bg-violet-20 {
            .job-title, .job-title a, .link-more, .job-meta .icon {
                color: @grape;
            }
        }
        .bg-blue-20 {
            .job-title, .link-more, .job-meta .icon {
                color: @brand-blue-primary;
            }
        }
        .bg-md-violet-20 {
            .job-title, .job-title a, .link-more, .job-meta .icon {
                @media screen and (min-width: 768px) {
                    color: @grape;
                }
            }
        }
        .bg-md-blue-20 {
            .job-title, .link-more, .job-meta .icon {
                @media screen and (min-width: 768px) {
                    color: @brand-blue-primary;
                }
            }
        }
    }
}

.find-out-more {
    p:last-child {
        margin-bottom: 0;
    }
    a {
        font-family: @font-family-brand;
        font-weight: 600;
    }
}

.job-title-share-buttons {
    .container {
        position: relative;
        .job-title {
            color: @brand-blue-primary;
        }
        .share-buttons {
            position: absolute;
            bottom: 0;
            right: 0
        }
    }
}

.careers-current-vacancies-security-warning, .careers-current-vacancies-terms-and-conditions {
    header {
        p {
            margin-bottom: 0;
        }
    }
}
.careers-current-vacancies-security-warning {
    article {
        border: 1px solid @orange;
        padding: 1.5rem;
        p {
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: @gray;
            }
        }
    }
}
.current-vacancies-filter-container {
    padding: 2.7rem 1.9rem 3.1rem;
    @media screen and (min-width: 768px) {
        padding: 1.8rem .5rem;

    }
    @media screen and (min-width: 1440px) {
        padding: 1.1rem 2.2rem 3rem;
    }
    h2 {
        margin-bottom: 2.3rem!important;
        font-size: 2.8rem!important;
        color: @brand-blue-primary;
        margin-bottom: 1.7rem!important;
    }
    #current-vacancies-filter-form {
        align-items: flex-end;
        justify-content: flex-start;
        .dropdown {
            width: 72.98%;
            @media screen and (min-width: 768px) {
                width: auto;
            }
            .jobs-location-filter, .jobs-type-filter {
                @media screen and (min-width: 768px) {
                    min-width: 27.4rem;
                }
                @media screen and (min-width: 1440px) {
                    width: 36.3rem;
                }
            }
        }
        button {
            background-color: @gray;
            height: 3.6rem;
            line-height: 1;
            color: @white;
        }
    }
}

.current-vacancies-filter-container { // to be merged into above
    background-color: #D7E9F7;
    text-align: left;
    @media screen and (min-width: 768px) {
        padding-left: 3.872%;
        padding-right: 3.872%;
        margin-left: .75rem;
        margin-right: .75rem;
    }
    @media screen and (min-width: 992px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media screen and (min-width: 1440px) {
        padding-left: 9.7rem;
        padding-right: 9.7rem;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    h2 {
        font-family: @font-family-base!important;
        font-size: 2.8rem;
    }
    #current-vacancies-filter-form {
        justify-content: space-between;
        .filter-block:first-child {
            flex: 0 0 100%;
            margin-bottom: 2.5rem;
            @media screen and (min-width: 768px) {
                flex: 0 1 27.4rem;
                margin-bottom: 0;
            }
            @media screen and (min-width: 1440px) {
                flex: 0 1 36.3rem;
            }
        }
        .filter-block:last-child {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: 0 0 100%;
            @media screen and (min-width: 768px) {
                flex: 0 1 36.8rem;
            }
            @media screen and (min-width: 1440px) {
                flex: 0 1 46.4rem;
            }
            label {
                flex: 0 0 100%;
            }
        }
    }
}

.fit_block.highlight-word {
    position: relative;
    justify-content: center;
    padding: 0;
    margin-bottom: 5rem;
    @media screen and (min-width: 1440px) {
        margin-bottom: 0;
    }
    .text_part {
        flex: 0 0 60%;
        padding-left: 0;
        &.width__50 {
            flex: 0 0 50%;
        }
        .text-part-heading[data-highlight-word] {
            color: @brand-blue-primary;
            font-family: @font-family-base!important;
            font-size: 2.5rem;
            font-weight: 400;
            letter-spacing: -.01em;
            margin-bottom: 2rem;
            line-height: 1;
            @media screen and (min-width: 768px) {
                font-size: 3rem;
            }
            @media screen and (min-width: 1440px) {
                font-size: 3.8rem;
            }
            &:before {
                content: attr(data-highlight-word);
                font-family: @font-family-brand!important;
                font-size: 9.2rem;
                font-weight: 600;
                letter-spacing: -.04em;
                text-align: left!important;
                line-height: .84;
                display: block;
                margin-bottom: 0;
                line-height: 1;
                position: static;
                @media screen and (min-width: 390px) {
                    margin-bottom: 2rem;
                    line-height: 1.076;
                }
                @media screen and (min-width: 768px) {
                    line-height: .84;
                    font-size: 26.8rem;
                    left: 1rem;
                    top: 0;
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                    margin-bottom: 0;
                }
                @media screen and (min-width: 1440px) {
                    font-size: 32.5rem;
                    left: 0;
                }
            }
        }
        & > div {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            @media screen and (min-width: 768px) {
                padding-left: 0;
                padding-right: 0;
            }
            p:not(.find-out-more) {
                a {
                    color: @gray;
                    text-decoration: underline;
                }
            }
        }
        h4 {
            font-weight: 600;
            font-size: 2rem;
        }
    }

    & > img {
        @media screen and (min-width: 768px) {
            float: none;
        }
    }

    .find-out-more {
        @media screen and (min-width: 768px) {
            white-space: nowrap;
        }
    }
    .careers-contact-form-container {
        background-color: #D7E9F7;
        text-align: left;
        padding: 1.7rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        @media screen and (min-width: 768px) {
            margin-left: 0;
            margin-right: 0;
        }
        @media screen and (min-width: 1440px) {
            padding: 2.8rem 2.3rem 3.2rem 2.5rem;
        }
        h2 {
            margin-top: 0;
        }
    }
    &.image-position-left {
        & > img {
            float: left;
        }
        & > img.hidden-xs {
            display: block!important;
        }
        & > img.visible-xs {
            display: none!important;
        }
        .text_part {
            & > div {
                clear: left;
            }
        }
    }
    &.image-position-right {
        & > img {
            float: right;
        }
        .text_part {
            .text-part-heading[data-highlight-word] {
                padding-left: 1.3rem;
                @media screen and (min-width: 768px) {
                    padding-left: 0;
                }
            }
            & > div {
                clear: right;
            }
            text-align: right;
        }
    }
}

#careers-pay-rise, #careers-give, #careers-thrive, #careers-shine, #careers-grow {
    .container {
        padding: 0;
    }
}

#careers-pay-rise {
    margin-bottom: 0;
    @media screen and (min-width: 1440px) {
        margin-bottom: 9rem;
    }
    .fit_block.highlight-word {
        display: block;
        margin: 0;
        @media screen and (min-width: 1440px) {
            display: flex;
            width: 116rem;
            margin: 0 0 0 2rem;
        }
        .image-part {
            max-width: 31.4rem;
            margin: 0 auto;
            float: none;
            @media screen and (min-width: 768px) {
                width: 48.8rem;
                max-width: 48.8rem;
            }
            @media screen and (min-width: 1440px) {
                width: 63.6rem;
                max-width: 63.6rem;
                margin-top: 30rem;
                margin-left: 0;
            }
        }
        .text_part {
            text-align: left;
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            @media screen and (min-width: 768px) {
                margin-top: 27rem;
            }
            @media screen and (min-width: 1440px) {
                padding-left: 0;
                padding-right: 0;
                margin-top: 35.3rem;
                max-width: 52.4rem;
            }
            .text-part-heading {
                padding-top: 1rem;
                max-width: calc(100% - 2rem);
                margin-top: 0;
                float: none!important;
                @media screen and (min-width: 390px) {
                    max-width: 36.2rem;
                }
                @media screen and (min-width: 768px) {
                    padding-top: 0;
                    max-width: 100%;
                }
                @media screen and (min-width: 1440px) {
                    font-size: 3.8rem;
                    margin-top: 2rem;
                }
                &:before {
                    width: 100%;
                    max-width: 32rem;
                    z-index: 1;
                    font-size: 8rem;
                    line-height: .84;
                    top: -21.5rem;
                    @media screen and (min-width: 390px) {
                        font-size: 8rem;
                        line-height: .875;
                        max-width: 34.9rem;
                    }
                    @media screen and (min-width: 768px) {
                        top: -28rem;
                        line-height: .8571;
                        font-size: 14rem;
                        max-width: 75rem;
                        top: 0;
                    }
                    @media screen and (min-width: 1440px) {
                        font-size: 17.5rem;
                        max-width: 90rem;
                    }
                }
            }
            & > div {
                & > div:first-child {
                    @media screen and (min-width: 1440px) {
                        margin-right: 9.8rem;
                    }
                    p:last-child {
                        margin-bottom: 2rem;
                        @media screen and (min-width: 1440px) {
                            margin-bottom: 4rem;
                        }
                    }
                }
            }
        }
    }
}

#careers-give {
    .fit_block.highlight-word {
        .image-part {
            max-width: 17.1rem;
            margin-top: -3rem;
            @media screen and (min-width: 390px) {
                margin-top: 0;
            }
            @media screen and (min-width: 768px) {
                width: 33.8rem;
                max-width: 33.8rem;
                margin-top: 13rem;
                margin-left: -5rem;
                order: 1;
            }
            @media screen and (min-width: 1440px) {
                width: 42.2rem;
                max-width: 42.2rem;
                margin-top: 13rem;
                margin-left: 0;
            }
        }
        .text_part {
            margin-top: 5rem;
            display: flex;
            flex-wrap: wrap;
            @media screen and (min-width: 390px) {
                margin-top: 0;
            }
            @media screen and (min-width: 768px) {
                margin-top: 28rem;
                order: 2;
            }
            @media screen and (min-width: 1440px) {
                margin-top: 33.1rem;
            }
            .text-part-heading {
                margin-top: 10rem;
                max-width: 36.8rem;
                padding-right: 1rem;
                order: 2;
                flex: 1 1 0;
                @media screen and (min-width: 390px) {
                    padding-top: 10rem;
                    padding-right: 0;
                    margin-top: 0;
                }
                @media screen and (min-width: 768px) {
                    padding-top: 0;
                    max-width: 100%;
                }
                @media screen and (min-width: 1440px) {
                    max-width: 52.2rem;
                }
                &:before {
                    left: 1rem;
                    top: -8rem;
                    width: 100%;
                    z-index: -1;
                    @media screen and (min-width: 768px) {
                        top: 0;
                    }
                }
            }
            .image-part {
                order: 1;
            }
            & > div {
                order: 3;
            }
        }
    }
}

#careers-thrive {
    .fit_block.highlight-word {
        margin-bottom: 3.5rem;
        flex-direction: row !important;
        display: block;
        @media screen and (min-width: 768px) {
            display: flex;
        }
        .image-part {
            max-width: 14.9rem;
            @media screen and (min-width: 768px) {
                width: 28.2rem;
                max-width: 28.2rem;
                margin-top: 16.2rem;
                margin-left: -1rem;
            }
            @media screen and (min-width: 1440px) {
                width: 41.6rem;
                max-width: 41.6rem;
                margin-top: 16.2rem;
                margin-left: 0;
            }
        }
        .text_part {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            text-align: left;
            @media screen and (min-width: 768px) {
                margin-top: 27rem;
            }
            @media screen and (min-width: 1440px) {
                margin-top: 35.3rem;
            }
            .text-part-heading {
                padding-top: 1rem;
                max-width: calc(100% - 14.9rem);
                margin-top: 5rem;
                order: 2;
                @media screen and (min-width: 390px) {
                    margin-top: 0;
                    padding-top: 12rem;
                }
                @media screen and (min-width: 768px) {
                    padding-top: 0;
                    max-width: 100%;
                }
                @media screen and (min-width: 1440px) {
                    margin-right: 9.8rem;
                }
                &:before {
                    left: 1rem;
                    top: -7rem;
                    width: 100%;
                    z-index: -1;
                    @media screen and (min-width: 768px) {
                        top: 0;
                    }
                }
            }
            .image-part {
                margin: 0;
                order: 1;
            }
            .text-part-body {
                flex: 0 0 100%;
                order: 3;
                & > div:first-child {
                    @media screen and (min-width: 1440px) {
                        margin-right: 9.8rem;
                    }
                    p:last-child {
                        margin-bottom: 2rem;
                        @media screen and (min-width: 1440px) {
                            margin-bottom: 4rem;
                        }
                    }
                }
            }
        }
    }
}

#careers-shine {
    .fit_block.highlight-word {
        margin-bottom: 1.6rem;
        .image-part {
            max-width: 12rem;
            @media screen and (min-width: 768px) {
                width: 23rem;
                max-width: 23rem;
                margin-top: 16.2rem;
                margin-left: -1.5rem;
            }
            @media screen and (min-width: 1440px) {
                width: 28rem;
                max-width: 28rem;
                margin-top: 16.2rem;
                margin-left: 0;
            }
        }
        .text_part {
            padding-top: 1rem;
            margin-top: 5rem;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            @media screen and (min-width: 390px) {
                margin-top: 0;
            }
            @media screen and (min-width: 768px) {
                padding-top: 0;
                margin-top: 27rem;
                order: 2;
            }
            @media screen and (min-width: 1440px) {
                margin-top: 35.3rem;
                margin-left: 5.6rem;
            }
            .text-part-heading {
                padding-right: 1rem;
                order: 2;
                flex: 1 1 0;
                @media screen and (min-width: 390px) {
                    max-width: 36.5rem;
                    padding-right: 0;
                }
                br {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }
                &:before {
                    left: 1rem;
                    top: -7rem;
                    width: 100%;
                    z-index: -1;
                    @media screen and (min-width: 768px) {
                        top: 0;
                    }
                }
            }
            .image-part {
                order: 1;
            }
            & > div {
                order: 3;
            }
        }
    }
}

#careers-grow {
    .fit_block.highlight-word {
        flex-direction: row !important;
        display: block;
        @media screen and (min-width: 768px) {
            display: flex;
        }
        .image-part {
            max-width: 14rem;
            @media screen and (min-width: 768px) {
                width: 27.8rem;
                max-width: 27.8rem;
                margin-top: 13rem;
                margin-left: 0;
            }
            @media screen and (min-width: 1440px) {
                width: 35.2rem;
                max-width: 35.2rem;
                margin-top: 6rem;
            }
        }
        .text_part {
            padding-top: 1rem;
            margin-top: 5rem;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-items: flex-end;
            .image-part {
                margin: 0;
            }
            @media screen and (min-width: 390px) {
                margin-top: 0;
            }
            @media screen and (min-width: 768px) {
                padding-top: 0;
                margin-top: 27rem;
                flex: 0 0 62%;
                max-width: 62%;
                margin-left: 2.1rem;
            }
            @media screen and (min-width: 1440px) {
                flex: 0 0 60%;
                margin-top: 30.2rem;
                margin-left: 0;
            }
            .text-part-heading {
                max-width: calc(100% - 14.2rem);
                order: 2;
                @media screen and (min-width: 768px) {
                    width: auto;
                    max-width: 100%;
                }
                &:before {
                    left: 1rem;
                    top: -7rem;
                    width: 100%;
                    z-index: -1;
                    @media screen and (min-width: 768px) {
                        top: 0;
                    }
                }
            }
            .text-part-body {
                order: 3;
                & > p {
                    margin-bottom: 4rem;
                }
            }
        }
    }
}