//
// Cards

.row-cards {
  .clearfix;

  [class*=" col-"],
  [class^="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.card {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition: all .4s;
  color: #fff !important;

  .inner {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    opacity: .7;
    transition: opacity .4s;
  }

  &:focus,
  &:hover {
    background-size: 120%;
    .inner {
      opacity: .9;
    }
  }

  a,
  a& {
    text-decoration: none;
  }
}


.card-fixed {
  height: 0;
  padding-bottom: 83%;
  overflow: hidden;
}


.card-body {
  padding: @components-spacer-lg;
  color: #fff !important;

  .card-media & {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: @components-spacer-md;
    color: #fff !important;
    transition: background .2s;
  }

  .card-crop-content & {
    height: 50%;

    & > div {
      height: 100%;
      overflow: hidden;
    }
  }

  .card-content-middle & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}


//
// Card content

.card-heading {
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  @media screen and (max-width: @screen-xs-max) {
    font-size: @font-size-large;
  }
}

.card-title {
  margin: 0 0 20px;
  font-size: @font-size-base;
  font-weight: 700;
}

.card-text {
  margin: 0;
  font-size: @font-size-base;
  font-family: @font-family-base;
}

.card-text-sm {
  margin: 0;
  font-size: @font-size-mini;
}


//
// Card variants

.card-default {
  .card-variant(@brand-gray-light, @text-color);
}

.card-primary {
  .card-variant(@brand-blue-primary, #FFF);
}

.card-primary-light {
  .card-variant(@brand-blue-primary-light, #FFF);
}

.card-secondary {
  .card-variant(@brand-blue-secondary, #FFF);
}

.card-secondary-light {
  .card-variant(@brand-blue-secondary-light, #FFF);
}

.card-info {
  .card-variant(@orange, #FFF);

  p {
    color: #F9E4CA;

    b {
      color: #FFF;
    }
  }
}


//
// Card mixins

.card-variant(@background, @color) {
  .card-body {
    color: @color;
  }

  &.card-media {
    .card-body {
      background-color: @background;
    }
    a&:hover,
    a&:focus {
      .card-body {
        background-color: fade(@background, 80%);
      }
    }
  }

  &:not(.card-media) {
    background-color: @background;

    .card-body {
      background: transparent;
    }

    a&:hover,
    a&:focus {
      background-color: fade(@background, 80%);
    }
  }
}