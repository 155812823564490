.op-region {
    .region-heading {
        background-color: @brand-blue-primary;
        color: @white;
        height: 6.4rem;
        line-height: 6.4rem;
        font-size: 2.6rem;
        padding: 0 2rem;
        font-family: @font-family-base!important;
        margin: 0;
        @media screen and (min-width: 768px) {
            padding: 0 3.2rem;
        }
    }
    .region-ops {
        padding: 0 2rem;
        @media screen and (min-width: 768px) {
            padding: 0 3.2rem;
        }
        .d-flex {
            column-gap: 2.5rem;
            margin: 2.5rem 0;
            padding: 2.5rem 0 0 0;
            @media screen and (min-width: 768px) {
                padding: 4.5rem 0;
                margin: 0;
            }
            &:not(:first-child) {
                border-top: 1px solid @brand-blue-primary;
            }
            .op-city-or-suburb {
                font-size: 2.6rem;
                line-height: 1.1538;
                font-weight: 700;
                color: @brand-blue-primary;
                flex: 0 0 100%;
                min-width: 16.2rem;
                margin-bottom: .5rem;
                @media screen and (min-width: 768px) {
                    flex: 0 0 auto;
                    margin-bottom: 0;
                }
                .partner-logo {
                    width: 15rem;
                    height: auto;
                    display: block;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    @media screen and (min-width: 768px) {
                        margin-bottom: 0;
                    }
                }
                .visibility-hidden {
                    @media screen and (max-width: 767.98px) {
                        display: none;
                    }
                }
            }
            .op-details {
                flex: 1 1 0;
                .op-location-name {
                    margin: 0 0 3.5rem 0;
                    font-weight: 400;
                    font-size: 2.6rem;
                    line-height: 1.1538;
                }
                .op-ops, .op-address-phone, .op-website-email {
                    display: flex;
                    justify-content: flex-start;
                    column-gap: 1.5rem;
                    flex-wrap: wrap;
                    & > * {
                        flex: 0 0 100%;
                        width: 100%;
                        margin-bottom: .5rem;
                        @media screen and (min-width: 600px) {
                            width: calc(50% - 7.5rem);
                            flex: 1 1 auto;
                            margin-bottom: 0;
                        }
                    }
                }
                .op-ops, .op-address-phone {
                    margin-bottom: 1.5rem;
                }
                .op-ops {
                    font-size: 2rem;
                    line-height: 1.15;
                }
            }
            &.op-city-or-suburb-hidden:not(:first-child) {
                @media screen and (min-width: 768px) {
                    border-top: none;
                }
                .op-details {
                    @media screen and (min-width: 768px) {
                        position: relative;
                        &:before {
                            content: "";
                            display: block;
                            border-top: 1px solid @brand-blue-primary;
                            position: absolute;
                            width: 100%;
                            top: -4rem;
                        }
                    }
                }
            }
        }
    }
}