.landing-section {
  margin: 0 0 40px;

  &-header {
    h2 {
      margin: 0;
    }
  }

  &-body {
    background-color: #F5F5F5;
    padding: 0;
    @media screen and (min-width: @screen-sm) {
      padding: 40px;
    }
  }
  
  &-footer {
    padding: 20px;
    background-color: #E9E9EA;
  }

  .heading {
    margin: 0 0 40px;
    font-size: 24px;
    font-family: @font-family-brand;
    font-weight: 600;
    color: @brand-primary;
    text-align: center;
  }

    @media screen and (max-width: @screen-xs) {
        margin: 10px 0;
    }      
}


//
// Hand Hygiene
//

@flask-1-height: 83% - 24%;
@flask-2-height: 87% - 24%;
@flask-3-height: 88% - 24%;


.landing-hand-hygiene, .landing-doctor-hygiene, .landing-national-hygiene {
  svg {
    width: 100%;
    height: auto;
    margin-right: -8%;
  }

  .flask-block {
    padding: 40px 40px 0;
    text-align: center;

    .flask-data {
      font-family: @font-family-brand;
      margin: 20px 0 0;

      & > div {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 160px;
        margin: 0 auto;

        & > div {

          & > span {
            display: block;
            font-size: 30px;
          }

          & + div {
            &:before {
              position: absolute;
              content: '–';
              width: 10px;
              right: 0;
              left: 0;
              margin: 10px auto 0;
              font-weight: 700;
            }
          }
        }
      }

      h3 {
        position: relative;
        margin-top: 10px;
        padding: 15px 10%;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          width: 120px;
          height: 1px;
          margin: auto;
        }
      }

      &.text-orange h3:before {
        background-color: @orange;
      }

      &.text-violet h3:before {
        background-color: @grape;
      }

      &.text-red h3:before {
        background-color: @pink;
      }

      &.text-blue h3:before {
        background-color: @brand-primary;
      }
    }

    .flask-rect-1 {
      height: @flask-1-height;
    }

    .flask-rect-2 {
      height: @flask-2-height;
    }

    .flask-rect-3 {
      height: @flask-3-height;
    }

    @media screen and (max-width: @screen-xs) {
        padding: 0;
    }    
  }
}


//
// Infection Prevention
//

.landing-infection-prevention-1 {
  svg {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin: 0 auto;
    @media screen and (min-width: @screen-sm) {
      min-height: 500px;
      padding: 0;
    }
  }
}

.landing-infection-prevention-2 {

  h3 {
    margin: 0 0 40px;
    font-size: 24px;
    font-family: @font-family-brand;
    font-weight: 600;
    color: #5EADDA;
    text-align: center;
  }

  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 300;
    font-family: @font-family-brand;
    text-align: center;

    @media (max-width: @screen-sm-max) {
      font-size: 16px;
    }
  }

  .landing-section-body > .row {
    display: flex;
    margin: 0;

    &:before,
    &:after {
      display: none;
    }

    .divider {
      flex: 0 0 1px;
      height: 200px;
      margin: 100px 7% 0;
      border-left: 1px dashed #5EADDA;
    }

    & > div {
      flex: 1;
      &:first-child {
        @media screen and (max-width: @screen-xs-max) {
          margin-bottom: 40px;
        }
      }
    }

    @media screen and (max-width: @screen-xs-max) {
        display: block;
    }        
  }

  .odometer .odometer-inside .odometer-digit:nth-child(1),
  .odometer .odometer-inside .odometer-formatting-mark:nth-child(2),
  .odometer .odometer-inside .odometer-digit:last-child {
    display: none
  }

  .odometer.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition-duration: 3s !important;
    -moz-transition-duration: 3s !important;
    -ms-transition-duration: 3s !important;
    -o-transition-duration: 3s !important;
    transition-duration: 3s !important
  }

  .numbers {

    & > div {
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      text-align: center;
      text-transform: uppercase;

      &,
      .odometer {
        font-family: @font-family-brand;
      }

      [id^=num-] {
        font-weight: 500;
        font-size: 60px;

        @media (max-width: @screen-sm-max) {
          font-size: 40px;
        }
      }

      .total {
        font-size: 55px;
        font-weight: 300;

        @media (max-width: @screen-sm-max) {
          font-size: 34px;
        }
      }

      .text {
        text-align: center;
        font-weight: 600;
        line-height: 1.2;
      }

      & + div {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid @hr-border;
      }
    }
  }

  svg {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    display: block;
    //height: auto;
    height: 200px;
    @media (min-width: @screen-sm) {
      height: 360px; // required by IE 11
    }
  }
}


//
// Patient Survey
//

.landing-patient-survey-1,
.landing-patient-survey-2 {
  font-family: @font-family-brand !important;

  .landing-section-body {
    padding: 0 0 10px;
  }

  .landing-section-footer {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
    @media (min-width: @screen-sm) {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .table-stat {
    background-color: @gray-light;

    & > *:nth-child(odd) {
      background-color: #EDEEEE;
    }

    & > .th {
      display: flex;
      padding: 20px 15px;
      font-size: 18px;
      text-align: center;
      font-weight: 600;
      background: none;
      @media (min-width: @screen-sm) {
        padding: 30px 40px;
      }
      small {
          font-size: 85% !important;
      }

      & > *:nth-child(1) {
        flex: 0 0 70%;
      }

      & > *:nth-child(2) {
        flex: 0 0 30%;
      }
    }

    & > .th-names,
    & > .tr {
      display: flex;
      padding: 0;
      @media (min-width: @screen-sm) {
        padding: 0 40px;
      }
      & > * {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-left: 1px dotted #929294;

        & > div > .progress {
          position: relative;
          display: block;
          height: auto;
          min-height: 24px;
          margin: 0;
          overflow: visible;
          border-radius: 0;
          box-shadow: none;
        }
      }

      & > *:nth-child(1) {
        flex: 0 0 40%;
        width: 40%;
        @media (min-width: @screen-sm) {
          flex: 0 0 50%;
          width: 50%;
        }
        & > div {
          width: 100%;
          padding-right: 50px;
          @media (min-width: @screen-sm) {
            padding-right: 70px;
          }
          & > .progress {
            position: relative;

            & > div {
              padding: 5px 10px;
              overflow: hidden;
              font-size: 18px;
              font-weight: 600;
              color: #FFF;
              @media (min-width: @screen-sm) {
                white-space: nowrap;
              }
              @media (max-width: @screen-sm-max) {
                font-size: 12px;
              }
            }

            & > svg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 100%;
              margin: auto 0 auto 4px;
              width: 40px;
              height: 40px;
            }
          }
        }
      }

      & > *:nth-child(2) {
        flex: 0 0 20%;

        & > .text {
          margin-left: 10px;
        }
      }

      & > *:nth-child(3),
      & > *:nth-child(4) {
        flex: 0 0 20%;
        @media (min-width: @screen-sm) {
          flex: 0 0 15%;
        }
      }

      & > *:nth-child(4) {
        border-right: 1px dotted #929294;
      }
    }

    & > .th-names {
      & > * {
        align-items: flex-start;
        padding: 0;
        font-size: 11px;
        font-weight: 600;
        @media (min-width: @screen-sm) {
          font-size: 12px;
        }
        & > * {
          padding: 0 5px 15px;
          @media (min-width: @screen-sm) {
            padding: 0 10px 15px;
          }
        }
      }
    }

    & > .tr {
      & > *:nth-child(2) {

        & > div {
          width: 100%;
          padding-right: 60px;

          & > .progress {
            .text {
              position: absolute;
              left: 100%;
              margin-left: 10px;
              font-size: 18px;
              font-weight: 600;
            }
            &.bg-orange {
              .text {
                color: @orange;
              }
            }
            &.bg-grape {
              .text {
                color: @grape;
              }
            }
          }
        }
      }

      & > *:nth-child(3),
      & > *:nth-child(4) {
        font-size: 24px;
        font-weight: 600;
        justify-content: center;
        background-color: rgba(0,0,0,.03);
      }
    }
  }
}

.landing-patient-survey-3 {
  & > .row {
    //display: flex;
    //flex-wrap: wrap;
    margin: 0 -1px;

    //&:after,
    //&:before {
    //  display: none;
    //}

    & > * {
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      //justify-content: space-between;
      //flex: 0 0 50%;
      width: 50%;
      float: left;
      padding: 20px;
      background-color: #F5F5F5;
      border-right: 1px solid #FFF;
      border-left: 1px solid #FFF;
      border-bottom: 2px solid #FFF;

      svg {
        display: block;
        width: 50%;
        height: 50%;
        //height: auto;
        margin: 0 auto 20px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: @brand-primary;
    }

    h3,
    p {
      text-align: center;
    }

    p {
      min-height: 88px;
      @media (min-width: @screen-sm) {
        min-height: 0;
        height: @line-height-base * 2em;
      }
    }

    .stat {
      width: 100%;
      margin: 0;

      &,
      .odometer {
        font-family: @font-family-brand !important;
      }

      .num {
        font-size: 32px;
        font-weight: 700;
      }

      .text {
        text-transform: uppercase;
        font-size: 12px;
        @media (min-width: @screen-sm) {
          height: @line-height-base * 4em;
        }
      }
    }
  }

  .odometer .odometer-inside .odometer-digit:nth-child(1) {
    display: none
  }
}

.landing-patient-survey-4 {

  .landing-section-body {

    svg {
      width: 100%;
      height: auto;
      min-height: 211px;
    }
  }

  .landing-section-footer {
    svg {
      display: block;
      width: 200px;
      height: auto;
      margin: 20px auto 0;
    }

    .odometer .odometer-inside .odometer-digit:nth-child(1) {
      display: none
    }

    svg + div,
    .odometer {
      font-family: @font-family-brand;
      font-weight: 500;
    }

    svg + div {
      margin-bottom: 20px;
      text-align: center;
    }

    .num {
      font-size: 60px;
    }

    .text {
      font-size: 18px;
    }
  }
}

.landing-patient-survey-5 {

  .landing-section-header + .landing-section-body {
    font-family: @font-family-brand;
    background-color: #E9E9EA;

    & > .row {
      display: flex;
      margin: 0;
      flex-wrap: wrap;
      & > div:first-child {
        width: 85%;
        flex-basis: 85%;
        flex-shrink: 0;
        margin: 10px auto;
        @media (min-width: @screen-sm) {
          width: 60%;
          flex-basis: 60%;
          padding-right: 20px;
        }
      }

      & > div:last-child {
        width: 85%;
        flex-basis: 85%;
        flex-shrink: 0;
        margin: 10px auto;
        @media (min-width: @screen-sm) {
          width: 40%;
          padding-left: 20px;
          flex-basis: 40%;
          border-left: 1px dotted @brand-primary;
        }
        h4 {
          margin-bottom: 30px;
          text-align: center;
          color: @brand-primary;
        }

        .score-calculated {
          font-family: @font-family-brand;
          font-weight: 500;
          white-space: nowrap;
          font-size: 14px;
          line-height: 1.2;

          @media (max-width: @screen-sm-max) {
            font-size: 12px;
          }

          & > * {
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
              padding-right: 12%;

              & > div {
                margin: 0 3% 10px;
              }
            }

            &:nth-child(2) {
              & > * {
                box-sizing: content-box;
              }

              & > *:first-child {
                flex: 0 0 25%;
              }

              & > *:nth-child(2) {
                flex: 0 0 37%;
                padding: 0 4%;

                & > .icon {
                  width: 100%;
                  height: 0;
                  padding-bottom: 40%;
                  background: url('../../images/patient-survey-5.png') no-repeat center;
                  background-size: contain;
                }
              }

              & > *:last-child {
                flex: 0 0 30%;
              }
            }

            &:last-child {
              justify-content: flex-end;
              margin-top: 10px;
              font-size: 16px;
              font-weight: 600;

              @media (max-width: @screen-sm-max) {
                font-size: 14px;
              }

              & > * {
                box-sizing: content-box;

                &:first-child {
                  flex: 0 0 20.5%;
                  padding-left: 2%;
                }

                &:nth-child(2) {
                  padding-left: 4%;
                  flex: 0 0 18.5%;
                }

                &:last-child {
                  flex: 0 0 30%;
                  text-align: left;
                }
              }
            }

            svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .odometer .odometer-inside .odometer-digit:nth-child(1) {
      display: none
    }
    .odometer {
      font-family: @font-family-brand;
      font-weight: 500;
    }

    .num {
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      & > svg,
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34px;

        @media (max-width: @screen-sm-max) {
          font-size: 20px;
        }
      }
    }

    .text {
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
    }
  }

  .landing-section-body + .landing-section-body {
    padding: 20px 0;
    font-family: @font-family-brand;
    font-weight: 500;
    background-color: #F5F5F5;
    @media (min-width: @screen-sm) {
      padding: 40px;
    }
    & > .row {
      display: flex;
      align-items: center;
      margin: 0;
      & > div:first-child {
        flex: 0 0 20%;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 600;
        padding: 0 10px;
      }

      & > div:last-child {
        padding: 0 10px;
        font-size: 20px;
        @media (min-width: @screen-sm) {
          padding: 0 20px 0 40px;
        }
        .text-blue b {
          font-size: 30px;
        }

        .text-violet b {
          font-size: 24px;
        }
      }
    }
  }
}

.blood-clots-patients-survey {
  font-family: @font-family-brand !important;

  .landing-section-header {
    h5 {
      margin-bottom: 20px;
    }
  }

  .landing-section-body {
    padding: 0 0 10px;
    background-color: transparent;
  }

  .table-stat {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    @media screen and (min-width: @screen-md) {
      padding: 0;
    }
    & > .tr {
      display: flex;
      padding: 0;
      & > * {
        align-items: center;
        padding: 15px 0;

        & > div > .progress {
          position: relative;
          display: block;
          height: auto;
          min-height: 24px;
          margin: 0;
          overflow: visible;
          border-radius: 0;
          box-shadow: none;
        }
      }

      & > *:nth-child(1) {
        flex: 0 0 120px;
        @media (min-width: @screen-sm) {
          flex: 0 0 80px;
        }
        @media (min-width: @screen-md) {
          flex: 0 0 20%;
        }
        .month, .year {
          font-family: @font-family-brand;
          font-size: 20px;
          @media screen and (min-width: @screen-sm) {
            display: block;
          }
        }
        .month {
          text-transform: uppercase;
          font-weight: 700;
          @media screen and (min-width: @screen-sm) {
            font-size: 26px;
            line-height: 1;
          }
          @media screen and (min-width: @screen-md) {
            font-weight: 900;
            font-size: 30px;
          }
        }
        .year {
          font-weight: 300;
          @media screen and (min-width: @screen-sm) {
            font-size: 30px;
            line-height: 1;
          }
          @media screen and (min-width: @screen-md) {
            font-size: 50px;
          }
        }
      }

      & > *:nth-child(2) {
        flex: 0 0 auto;
        width: ~'calc(100% - 120px)';
        @media (min-width: @screen-sm) {
          flex: 0 0 50%;
          width: 50%;
        }
        & > div {
          width: 100%;
          padding-right: 50px;
          @media (min-width: @screen-sm) {
            padding-right: 70px;
          }
          & > .progress {
            position: relative;

            & > div {
              padding: 5px 10px;
              overflow: hidden;
              font-size: 18px;
              font-weight: 600;
              color: #FFF;
              @media (min-width: @screen-sm) {
                white-space: nowrap;
                height: 50px;
                font-size: 22px;
                padding: 0 0 0 20px;
                line-height: 50px;
              }
              @media (min-width: @screen-md) {
                height: 70px;
                line-height: 70px;
                font-size: 30px;
              }
            }

            & > svg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 100%;
              margin: auto 0 auto 4px;
              width: 40px;
              height: 40px;
              @media (min-width: @screen-sm) {
                width: 60px;
                height: 60px;
              }
              @media (min-width: @screen-md) {
                width: 70px;
                height: 70px;
              }
            }
          }
        }
      }

    }

    & > .th-names {
      & > * {
        align-items: flex-start;
        padding: 0;
        font-size: 11px;
        font-weight: 600;
        @media (min-width: @screen-sm) {
          font-size: 12px;
        }
        & > * {
          padding: 0 5px 15px;
          @media (min-width: @screen-sm) {
            padding: 0 10px 15px;
          }
        }
      }
    }

    & > .tr {
      & > *:nth-child(2) {

        & > div {
          width: 100%;
          padding-right: 60px;

          & > .progress {
            .text {
              position: absolute;
              left: 100%;
              margin-left: 10px;
              font-size: 18px;
              font-weight: 600;
            }
            &.bg-orange {
              .text {
                color: @orange;
              }
            }
            &.bg-grape {
              .text {
                color: @grape;
              }
            }
          }
        }
      }

      & > *:nth-child(3),
      & > *:nth-child(4) {
        font-size: 24px;
        font-weight: 600;
        justify-content: center;
        background-color: rgba(0,0,0,.03);
      }
    }
  }
}