.block-locations {
    position: relative;
    height: 90rem;
    background-color: @brand-gray-light;

    @media (max-width: @screen-sm-max) {
        height: auto;
    }
}

.block-locations-body {
    //max-height: 800px;
    padding: 30px 0 30px 15px;
    overflow-y: auto;
    h3 {
        .font_brand;
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 10px 0;
    }
    @media (max-width: @screen-sm-max) {
        max-height: none;
        padding-left: 0;
    }

    .nav {
        margin-bottom: 40px;
        & > li {
            & > a, & > span {
                display: inline-block;
                padding: 3px 0;
                color: @text-color;
                &:focus,
                &:hover {
                    color: @brand-primary;
                    background-color: transparent;
                }
            }

            .partner {
                display: inline-block;
                font-size: 10px;
                color: @orange;
                text-transform: uppercase;
            }
        }
    }
}

.block-locations-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    height: 800px;
    overflow: hidden;
    background-color: #ebebeb;
    z-index: 15;
    @media (max-width: @screen-md-max) {
        margin-right: -150px;
    }

    @media (max-width: @screen-sm-max) {
        position: static;
        margin: 0;
    }
}

.block-spec-map {
    position: relative;
    height: 800px;
    overflow: hidden;
    background-color: #ebebeb;
    z-index: 15;
    .map_data {
        position: absolute;
        &.header {
            top: 15px;
            left: 0;
            width: 100%;
            h3 {
                .font_brand;
                font-weight: 600;
                font-size: 24px;
            }
        }
    }
    #map_description {
        right: ~"calc( 50% - 500px )" !important;
        width: 220px;
        font-size: 14px;
        text-align: right;
    }

    #map_legend {
        right: ~"calc( 50% - 360px )" !important;
        top: 60px !important;
        width: 260px;
        font-size: 13px;
        h5 {
            .font_brand;
            font-size: 22px;
            font-weight: 600;
        }
        .fa {
            margin-right: 5px;
            margin-top: 5px;
            font-size: 16px;
            font-weight: 900;
        }
        .text {
            display: block;
            margin-bottom: 20px;
        }
        .legend_word {
            font-size: 16px;
        }
    }
    #reset_link {
        display: none;
        padding: 10px 40px;
        background-color: @grey_2;
        img {
            width: 10vw;
            max-width: 100px;
            height: auto;
            display: block;
        }
        &:hover {
            background-color: @brand-blue-primary;
        }
    }
    &.selection_process {
        width: 50%;
        float: left;
        #map_description, #map_legend, .map_data.header {
            display: none;
        }
        #reset_link {
            display: block;
        }
    }
    @media (max-width: @screen-md-max) {
        margin-right: 0;
    }

    @media (max-width: @screen-sm-max) {
        position: static;
        margin: 0;
    }
    @media (max-width: @screen-xs-max) {
        display: none;
    }
}
