
.no_padding {
    padding: 0 0;
}

.no_margin {
    margin: 0;
}

.text_center {
    text-align: center;
    ul {
        text-align: left;
    }
}

.text_left {
    text-align: left;
}

.text_right {
    text-align: right;
}

b, strong {
    font-weight: 600;
}

h3 small, h4 small, h5 small, p small {
    color: inherit;
    vertical-align: top;
    font-size: 50%;
}

.bg(@size,@repeat,@position){
    -webkit-background-size: @size;
    background-size: @size;
    background-repeat: @repeat;
    background-position: @position;
}
.flex-display(@display: flex, @direction: row, @wrap: nowrap, @justify: flex-start, @align-items: stretch, @align-content: center) {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-flex-direction: @direction;
    -moz-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
    -webkit-flex-wrap: @wrap;
    -moz-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
    -webkit-justify-content: @justify;
    -moz-justify-content: @justify;
    -ms-justify-content: @justify;
    justify-content: @justify;
    -webkit-align-items: @align-items;
    -moz-align-items: @align-items;
    -ms-align-items: @align-items;
    align-items: @align-items;
    align-content: @align-content;
    -webkit-align-content: @align-content;
}

.use_only_if( @number , @number2 , @rules ) when ( @number > 0 ) {
    // @number is number of elements, @number 2 is always 1. Example usage: .use_only_if(4, 1, {flex-basis: 25%}) - will generate rules used only if exactly 4 siblings
    .use_only_if(@number - 1,@number2 + 1, @rules);

    &:nth-child(@{number}):nth-last-child(@{number2}) {
        @rules();
    }
}

.placeholder(@rules){
    &::-webkit-input-placeholder{
        @rules();
    }

    &:-moz-placeholder{
        @rules();
    }

    &::-moz-placeholder{
        @rules();
    }

    &:-ms-input-placeholder{
        @rules();
    }
}

.flex_hor_3 {
    .flex-display(flex, row, nowrap, space-between, stretch, center);
    .item {
        flex: 0 1 auto;
        flex-basis: ~"calc( 33% - 10px )";
    }
    @media screen and (max-width: @screen-xs-max) {
        flex-direction: column;
        display: block;
        .item {
            flex-basis: 100%;
            margin-bottom: 15px;
        }
    }
}

.transition(@time:0.3s, @prop:all){
    -webkit-transition: @prop @time;
    -moz-transition: @prop @time;
    -ms-transition: @prop @time;
    -o-transition: @prop @time;
    transition: @prop @time;
}

.transform(@param) {
    -ms-transform: @param; /* IE 9 */
    -webkit-transform: @param; /* Chrome, Safari, Opera */
    transform: @param;
}

.flex_hor_4 {
    .flex-display(flex, row, nowrap, space-between, stretch, center);
    .item {
        flex: 1 0 auto;
        flex-basis: ~"calc( 25% - 10px )";
    }
    @media screen and (max-width: @screen-sm-max) {
        flex-direction: column;
        .item {
            flex-basis: 100%;
            margin-bottom: 15px;
            display: block;
        }
    }
}

a {
    &:hover, &:focus {
        color: #1f8ac7;
    }
}

.with_zoom {
    .zoom_on_hover;
}

.centered {
    text-align: center;
}

.text-right {
    text-align: right;
}
.text-lg-right {
    @media screen and (min-width: 1200px) {
        text-align: right;
    }
}
.xs_left_aligned {
    @media screen and (max-width: @screen-xs-max) {
        text-align: left;
    }
}
.size24 {
    font-size: 24px;
}
.size20 {
    font-size: 20px;
}
.size18 {
    font-size: 18px;
}
.flex_hor {
    .flex-display(flex, row, nowrap, space-between, stretch, center);
    .item {
        //flex: 1 0 0;
        min-width: 0;
        @media screen and (min-width: 768px) {
            .use_only_if( 2 , 1 , { flex-basis: 50% } );
            .use_only_if( 4 , 1 , { flex-basis: 25% } );
            flex-basis: 33%;
            margin: 0 10px;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            .img_part {
                .inner.with_zoom {
                    .transform(scale(1.03));
                }
            }
        }
    }
    &.longlist {
        flex-wrap: wrap;
        .item {
            flex: 0 1 32%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
            @media screen and (max-width: @screen-xs-max) {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }
    .img_part {
        padding-bottom: 85.19%;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        overflow: hidden;
        .inner {
            -webkit-background-size: cover;
            background-size: cover;
            position: absolute;
            background-repeat: no-repeat;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            &.with_zoom {
                .transition(0.4s, all);
            }
            .img-responsive {
                @media screen and (max-width: 767.8px) {
                    width: 100%;
                }
            }
        }
        &.img_center {
            padding-bottom: 75%;
            .inner {
                -webkit-background-size: 155px 155px;
                background-size: 155px 155px;
                background-position: center center;
            }
        }
    }
    .item:first-child:nth-last-child(2), .item:last-child:nth-child(2) {
        .img_part {
            padding-bottom: 300px;
            max-height: 300px;
            @media screen and (max-width: @screen-xs-max) {
                padding-bottom: 150px;
            }
        }
    }
    &.num-blocks-3 {
        .img_part {
            padding-bottom: 61%;
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        flex-wrap: wrap;
        padding: 0 15px;
        .item {
            padding: 0 5px;
            margin: 0 0 15px 0;
        }
        .img_part {
            &.img_center {
                padding-bottom: 170px;
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        padding: 0 15px;
        flex-wrap: wrap;
        .item {
            flex: 0 0 100%;
            margin: 0 0 15px 0;
            &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                margin: 0 0 15px 0;
            }
        }
    }
}

#about-us-our-values {
    @media screen and (max-width: @screen-xs-max) {
        padding: 0 15px;
        .flex_hor {
            padding: 0;
        }
        .item {
            flex-basis: 50%!important;
            &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                margin: 0 0 0 0;
            }
            .img_part {
                padding-bottom: 90px;
                margin-bottom: 10px;
                .inner {
                    -webkit-background-size: 90px 90px;
                    background-size: 90px 90px;
                }
            }
            h4 {
                margin-bottom: 15px;
                font-size: 17px;
                line-height: 17px;
            }
            .text_part {
                margin-bottom: 8px;
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        .for_block_title.text_center h2 {
            margin: 0 auto 20px;
            text-align: center;
        }
    }
}
.m_b_40 {
    margin-bottom: 40px;
}

.m_0_0_30 {
    margin: 0 0 30px;
}

.m_b_25 {
    margin-bottom: 25px;
}

.m_b_15 {
    margin-bottom: 15px;
}
.m_b_20 {
    margin-bottom: 20px;
}
.m_b_30 {
    margin-bottom: 30px;
}
.m_t_30{
    margin-top: 30px;
}
.m_t_25 {
    margin-top: 25px;
}

.m_t_15 {
    margin-top: 15px;
}

.m_t_0 {
    margin-top: 0;
}

.mb-15-xs-max {
    @media screen and (max-width: @screen-xs-max) {
        margin-bottom: 15px;
    }
}

.mb-20-xs-max {
    @media screen and (max-width: @screen-xs-max) {
        margin-bottom: 20px;
    }
}

.mb-30-xs-max {
    @media screen and (max-width: @screen-xs-max) {
        margin-bottom: 30px;
    }
}

.mb-15-sm-max {
    @media screen and (max-width: @screen-sm-max) {
        margin-bottom: 15px;
    }
}

.mb-20-sm-max {
    @media screen and (max-width: @screen-sm-max) {
        margin-bottom: 20px;
    }
}

.mb-30-sm-max {
    @media screen and (max-width: @screen-sm-max) {
        margin-bottom: 30px;
    }
}

.spacer25 {
    min-height: 25px;
}

.img_bg {
    .bg(cover,no-repeat,center center);
    .container {
        position: relative;
    }
    .overlay {
        &.top_bottom_overlay {
            background: -moz-linear-gradient(top,  rgba(0,0,0,0.2) 0%, rgba(6,6,4,0.2) 40%, rgba(9,9,6,0.05) 60%, rgba(14,15,11,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(6,6,4,0.2) 40%,rgba(9,9,6,0.05) 60%,rgba(14,15,11,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(6,6,4,0.2) 40%,rgba(9,9,6,0.05) 60%,rgba(14,15,11,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#000e0f0b',GradientType=0 ); /* IE6-9 */
        }
        &.left_right_overlay {
            background: -moz-linear-gradient(left,  rgba(0,0,0,0.2) 0%, rgba(6,6,4,0.2) 40%, rgba(9,9,6,0.05) 60%, rgba(14,15,11,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(0,0,0,0.2) 0%,rgba(6,6,4,0.2) 40%,rgba(9,9,6,0.05) 60%,rgba(14,15,11,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(0,0,0,0.2) 0%,rgba(6,6,4,0.2) 40%,rgba(9,9,6,0.05) 60%,rgba(14,15,11,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#000e0f0b',GradientType=1 ); /* IE6-9 */
        }
    }
    &.no_bg_xs {
        @media screen and (max-width: @screen-xs-max) {
            background-image: none!important;
            background-color: @fog;
            .overlay {
                background: none!important;
            }
        }
    }
}

.line_link {
    position: static;
    display: inline-block;
    padding: .7rem 3.3rem;
    background-color: @mandarin;
    color: @white;
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.6rem;
    border-radius: 2px;
    .transition(.3s, background-color);
    outline: none!important;
    &:hover, &:active, &:focus {
        text-decoration: none;
        background-color: @yellow;
        color: @white;
    }
    &.bg-blue {
        &:hover, &:active, &:focus {
            background-color: @brand-blue-primary-light;
        }
    }
    i {
        display: none;
        font-size: 24px;
        line-height: 0;
        vertical-align: middle;
        margin-left: 15px;
    }
    @media screen and (max-width: @screen-sm-max) {
        i {
            position: absolute;
            right: 15px;
            bottom: 34px;
        }
    }
    @media screen and (max-width: @screen-xs) {
        width: 100%;
    }
}

.h_30 {
    height: 30px;
}

.p_60_0 {
    padding: 60px 0;
}

.p_15_0 {
    padding: 15px 0;
}

.p_20_0 {
    padding: 20px 0;
}

.p_30_0 {
    padding: 30px 0;
}

.p_80_0_80 {
    padding: 80px 0 80px;
}

.p_75_0_75 {
    padding: 8.5rem 0;
}

.p_40_0 {
    padding: 40px 0;
}

.p_15_0_15 {
    padding: 15px 0 15px;
}

.p_30_0_5 {
    padding: 30px 0 5px;
}

.p_30_0_0 {
    padding: 30px 0 0;
}

.p_30_0_50 {
    padding: 30px 0 50px;
}

.p_0_30 {
    padding: 0 30px;
}

.p_15_15 {
    padding: 15px 15px;
}

.p_15_30 {
    padding: 15px 30px;
}

.p_20_20 {
    padding: 20px 20px;
}

.p_b_100 {
    padding-bottom: 100px;
}

.p_40_40 {
    padding: 40px 40px;
}

.p_10_30 {
    padding: 10px 30px;
}

.p_20_30 {
    padding: 20px 30px;
}

.p_60_0_130 {
    padding: 60px 0 130px;
}

.p_60_0_130_md {
    @media screen and (min-width: 768px) {
        padding: 60px 0 130px;
    }
}

.p_30_0_130 {
    padding: 30px 0 130px;
}

.p_0_0_130 {
    padding: 0 0 130px;
}

.p_0_0_50 {
    padding: 0 0 50px;
}

.p_30_15 {
    padding: 30px 15px;
}
.p_60_0_0 {
    padding: 60px 0 0;
}

.disclaimer_text {
    font-style: italic;
    font-size: 13px;
}

.m_b_85 {
    margin-bottom: 85px;
}

.m_b_70 {
    margin-bottom: 70px;
}

.m_t_40 {
    margin-top: 40px;
}

.p_0_0_15 {
    padding: 0 0 15px;
}

.p_b_0 {
    padding-bottom: 0;
}
.m-b-30 {
    margin-bottom: 3rem;
}
.m-b-50 {
    margin-bottom: 50px;
}
.m-b-70 {
    margin-bottom: 70px;
}
.p-l-0 {
    padding-left: 0;
}
.p-0 {
    padding: 0;
}
.pt-lg-50 {
    @media screen and (min-width: 992px) {
        padding-top: 5rem;
    }
}
.pv-md-55 {
    @media screen and (min-width: 600px) {
        padding-top: 5.5rem!important;
        padding-bottom: 5.5rem!important;
    }
}
.pv-lg-85 {
    @media screen and (min-width: 992px) {
        padding-top: 8.5rem!important;
        padding-bottom: 8.5rem!important;
    }
}

@media screen and (max-width: @screen-md-max) {
    .p_15_30_md {
        padding: 15px 30px;
    }
    .p_25_0_md {
        padding: 25px 0;
    }
    .p_0_0_60_md {
        padding: 0 0 60px;
    }
}
@media screen and (max-width: @screen-sm-max) {
    .container {
        width: 100%;
    }
    .p_25_0_sm {
        padding: 25px 0;
    }
    .m_t_25_sm {
        margin-top: 25px;
    }
}
@media screen and (max-width: @screen-xs-max) {
    .p_30_0_60_xs {
        padding: 30px 0 60px;
    }

    .p_15_15_xs {
        padding: 15px 15px;
    }

    .p_0_0_60_xs {
        padding: 0 0 60px;
    }
    .p_25_0_xs {
        padding: 25px 0;
    }
    .p_15_0_xs {
        padding: 15px 0;
    }

    .p_0_15_xs {
        padding: 0 15px;
    }

    .m_t_0_xs {
        margin-top: 0;
    }
    .m_t_25_xs {
        margin-top: 25px;
    }
}

.white_text {
    color: @white;
    a {
        color: @white !important;
        text-decoration: underline;
    }
}

.font_brand {
    font-family: @font-family-brand;
}
.font_bold {
    font-weight: bold;
}
.imp_text {
    font-size: 20px;
    line-height: 28px;
    h2 {
        font-family: @font-family-brand;
        font-weight: 600;
        letter-spacing: 0.5px;
        .page-title;
        margin-top: 0;
    }
    h4, h6 {
        font-family: @font-family-brand;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    h4 {
        text-transform: none;
        font-size: 24px;
        margin-top: 0;
        @media screen and (max-width: @screen-xs-max) {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0;
        }
    }
    h6 {
        text-transform: none;
        font-size: 20px;
    }
}

.usual_text {
    font-size: 16px;
    line-height: 24.5px;
    h3 {
        font-family: @font-family-brand;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }
}

.zoom_on_hover {
    .transition(0.4s, all);
    .bg(cover,no-repeat,center center);
    &:hover {
        .transform(scale(1.03));
    }
}

.img_bg_link {
    display: block;
    padding-bottom: 60%;
    .bg(100% 100%,no-repeat,center center);
    position: relative;
    overflow: hidden;
    &.person_face {
        padding-bottom: 120%;
    }
    .inner {
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .zoom_on_hover;
    }
}

.orange_bg {
    background-color: @orange;
}

.orange_bg_2 {
    background-color: @mandarin;
    .forms-and-leaflets & {
        background-color: #ae0855;
    }
}

.orange_bg_3 {
    background-color: @mandarin;
    .forms-and-leaflets & {
        background-color: #692c86;
    }
}

.red_bg {
    background-color: @pink;
}

.fog_bg {
    background-color: @fog;
}

.blue_bg {
    background-color: @brand-blue-primary;
}

.violet_bg {
    background-color: @grape;
}

.bg-colour(@colour) {
    background-color: @colour;
}

.colour(@colour) {
    color: @colour;
}



.container {
    position: relative;
}

.sm_no_padding {
    @media screen and (max-width: @screen-sm-max) {
        padding: 0;
        &.container {
            width: 100%;
        }
    }
}

.xs_no_padding {
    @media screen and (max-width: @screen-xs-max) {
        padding: 0;
        &.container {
            width: 100%;
        }
    }
}

.container {
    &.lg_no_padding {
        @media screen and (max-width: @screen-md-max) {
            padding: 0;
            width: 100%;
        }
    }
}

.m_b_110 {
    margin-bottom: 110px;
}

.m_b_0 {
    margin-bottom: 0;
}

.m_b_0_xs {
    @media screen and (max-width: @screen-xs-max) {
        margin-bottom: 0;
    }
}

.center-sm {
    @media screen and (max-width: @screen-sm-max) {
        text-align: center;
    }
}

.img-responsive {
    &.full_width {
        width: 100%;
    }
}

[data-link] {
    cursor: pointer;
}

.blue_button {
    display: inline-block;
    background-color: @brand-blue-primary;
    color: @white;
    padding: 20px 40px;
    border-radius: 5px;
    font-weight: 600;
    &:hover {
        background-color: @brand-blue-primary-light;
        text-decoration: none;
        color: @white;
    }
    &.b_small {
        padding: 5px 10px;
    }
}

.btn-blue {
    display: inline-block;
    background-color: @brand-blue-primary;
    color: @white;
    border-radius: 2px;
    font-weight: 600;
    &:hover, &:active {
        background-color: @brand-blue-primary-light;
        text-decoration: none;
        color: @white;
    }
}

.mx-30 {
    margin-left: 3rem;
    margin-right: 3rem;
}

.mx-lg-0 {
    @media screen and (min-width: 992px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.mt-20 {
    margin-top: 2rem;
}

.mt-35 {
    margin-top: 3.5rem;
}

.mt-50 {
    margin-top: 5rem;
}

.mt-lg-50 {
    @media screen and (min-width: 992px) {
        margin-top: 5rem;
    }
}

.mt-md-50 {
    @media screen and (min-width: 768px) {
        margin-top: 5rem;
    }
}

.mt-md-60 {
    @media screen and (min-width: 768px) {
        margin-top: 6rem;
    }
}

.mt-lg-60 {
    @media screen and (min-width: 992px) {
        margin-top: 6rem;
    }
}

.mb-25 {
    margin-bottom: 2.5rem;
}

.mb-28 {
    margin-bottom: 2.8rem;
}

.mb-lg-0 {
    @media screen and (min-width: 992px) {
        margin-bottom: 0;
    }
}

.mb-lg-30 {
    @media screen and (min-width: 992px) {
        margin-bottom: 3rem;
    }
}

.mw-285 {
    max-width: 28.5rem;
}

.with_bricks {
    ul {
        list-style: none;
        margin: 50px -27px 25px;
        padding: 0 0 0 10px;
        .flex-display(flex,row,wrap,flex-start,flex-start,space-between);
        li {
            display: inline-block;
            flex: 0 0 32%;
            margin: 0 5px 10px;
            padding: 20px 5px;
            background-color: @brand-blue-primary;
            color: @white;
        }
    }
    @media screen and (max-width: @screen-md-max) {
        ul {
            li {
                flex: 0 0 48%;
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        ul {
            padding-left: 0;
            li {
                flex: 0 0 98%;
            }
        }
    }
}

.g_w_bg {
    background: rgb(242,242,242); /* Old browsers */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQzJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQzJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(255,255,255,1) 43%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(242,242,242,1) 0%,rgba(242,242,242,1) 43%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(242,242,242,1) 0%,rgba(242,242,242,1) 43%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=1 ); /* IE6-8 */
}

.two_colors(@left_color, @right_color, @offset, @hide_on_xs) {
    position: relative;
    z-index: 0;
    margin: 0 0;
    &::before, &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
    }
    &::before {
        left: 0;
        right: (1 - @offset)*100vw;
        background-color: @left_color;
    }
    &::after {
        left: @offset*100vw;
        right: 0;
        background-color: @right_color;
    }
    &::before when (@hide_on_xs) {
        @media screen and (max-width: @screen-xs-max) {
            display: none;
        }
    }
    &::after when (@hide_on_xs) {
        @media screen and (max-width: @screen-xs-max) {
            display: none;
        }
    }
}

.cb_radio(@pos_1, @pos_2){
    content: "";
    background-image: data-uri('../images/cb_radio_pics.png');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: @pos_1 @pos_2;
}

.fa_radio(@checked) when (@checked = true) {
    content: @fa-var-check-square;
    color: @brand-blue-primary;
}
.fa_radio(@checked) when (@checked = false) {
    content: @fa-var-stop;
    color: fade(@brand-blue-primary,20%);
}

.styled_list {
    list-style-type: none;
    padding-left: 20px;
    li:before {
        content: '\2014';
        position: absolute;
        margin-left: -20px;
    }
}

.checklist_list {
    padding: 0 0 0 30px;
    list-style: none;
    li {
        position: relative;
        padding-left: 20px;

        &::before {
            content: "\E918";
            font-family: 'icomoon', sans-serif !important;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            left: -15px;
            top: 0;
        }
    }
    li + li {
        margin-top: 15px;
    }
    &.crossed {
        li {
            &::before {
                content: "\E909";
            }
        }
    }
    &.lined {
        li {
            &::before {
                content: "—";
                top: -3px;
            }
        }
    }
}
.styled_form {
    h3 {
        font-family: @font-family-brand;
        font-size: 30px;
        line-height: 25px;
        margin: 0 0 30px;
        font-weight: 600;
    }
    a {
        color: @text-color;
    }
    .input-group {
        margin-bottom: 15px;
        width: 100%;
        border: 1px solid @fog;
        &.text_right {
            border: 0;
        }
        .input-group-addon {
            background-color: @white;
            width: 150px;
            text-align: left;
            padding: 10px 10px;
            border: none !important;
            vertical-align: top;
        }
        input, select, textarea {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &::-webkit-input-placeholder {
                color: @fog-tr;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: @fog-tr;
            }

            &::-moz-placeholder {  /* Firefox 19+ */
                color: @fog-tr;
            }

            &:-ms-input-placeholder {
                color: @fog-tr;
            }
            &[type='submit'] {
                font-weight: bold;
                padding: 8px 25px;
            }
        }
        .input-group-addon + textarea {
            width: 100% !important;
        }
    }
    .g-recaptcha {
        margin-bottom: 1.5rem;
    }
    .for_cb {
        padding: 10px 0;
        input[type=checkbox] {
            appearance:none;
            -webkit-appearance:none;
            -moz-appearance:none;
            top: 20px;
            left: 10px;
            position: relative;
        }
        label {
            font-weight: normal;
            font-size: 13px;
            position: relative;
            padding-left: 3rem;
            &::before {
                position: absolute;
                left: 0;
                font-size: 20px;
                .fa-icon();
                .fas();
                .fa_radio(false);
            }
        }
        input[type=checkbox]:checked + label {
            &::before {
                .fa_radio(true);
            }
        }
    }
}
.f_right {
    float: right;
}
.f_left {
    float: left;
}
.styled_modal {
    .modal-content {
        border-radius: 0;
    }
    .close {
        position: absolute;
        top: 2px;
        right: 0;
        padding: 15px 20px;
        background-color: @fog;
        opacity: .8;
        outline: none!important;
        z-index: 2;
        &:hover {
            opacity: 1;
        }
    }
    .modal-header {
        border-bottom: 0;
    }
    .modal-footer {
        border-top: 0;
    }
    .modal-body {
        padding: 60px 60px 30px;
        .styled_form;
        h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
        }
    }
    h2, h3 {
        .font_brand;
        font-weight: 500;
    }
    h2 {
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 36px;
    }
    form {
        padding-top: 15px;
    }
    #person_name {
        font-size: 20px;
    }
    #person_position, #person_achievments {
        margin-bottom: 20px;
        display: inline-block;
        width: 100%;
    }
    @media screen and (max-width: @screen-xs-max) {
        img.full_width {
            max-width: 250px;
            margin: 0 auto 15px;
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .modal-body {
            padding: 15px 15px;
        }
        h2 {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: @screen-xs-max) {
    .text-xs-left {
        text-align: left;
    }
}

.blue_color {
    color: @brand-blue-primary;
}

.red_color {
    color: @pink;
}

.cap_me {
    text-transform: capitalize;
}

.disabled_element {
    pointer-events: none;
    * {
        pointer-events: none;
    }
    .name {
        color: @gray!important;
    }
    .achievments {
        color: #b1b1b1!important;
    }
    &:hover {
        color: @gray!important;
    }
}

.blue_panel {
    background-color: fade(@brand-blue-primary, 20%);
    border-bottom: 1px solid @brand-blue-primary;
    h4 {
        color: @white;
        .font_brand;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        background-color: @brand-blue-primary;
        padding: 10px 15px;
        margin-bottom: 30px;
    }
    p {
        padding: 0 30px;
        &:last-child {
            padding-bottom: 30px;
            margin-bottom: 0;
        }
    }
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

.d-block {
    display: block;
}

.bg-blue{ 
    background-color: #008BCE; 
    color: white; 
    a {
        color: white; 
    }
}
.bg-lightBlue{ background-color: #E7F6FE; }
.color-blue{ color: #008BCE;}
.font-normal{ font-weight: 400; }

.contribution { 
    font-family: @font-family-brand;
    background-color: #ebecec;
    h2 {
        margin: 0;
        font-size: 4rem;
        @media screen and (max-width: 1199.98px) {
            font-size: 36px;
        }
        @media screen and (max-width: 767.98px) {
            font-size: 3rem;
        }
        br {
            @media screen and (max-width: 991.98px) {
                display: none;
            }
        }
    }
    h3 {
        margin-top: 0;
        margin-bottom: 22px;
        //color: #279bd4;
    }
    div.number-icons { 
        display: flex; 
        flex-wrap: wrap; 
        justify-content: center; 
        align-items: stretch; 
        row-gap: 1rem;
        margin: 3rem -.5rem;
        max-width: 1980px;
        @media screen and (max-width: 1024px) {
            width: 100%;
            margin: 2rem 0;
        }
        div.number-icon {
            display: flex;
            flex: 0 0 auto;
            justify-content: center;
            width: 100%;
            padding: 0 .5rem;
            @media screen and (min-width: 600px) {
                width: 50%;
            }
            @media screen and (min-width: 992px) {
                width: 33.33333%;
            }
            .number-text { 
                display: flex; 
                flex-direction: column; 
                justify-content: space-around;
                line-height: 1.1;
                background-color: #0E5688;
                width: 100%;
                padding: 0 2rem 2rem;
                .number {
                    color: #009BDF;
                    font-size: 8.6rem;
                    font-weight: 600;
                    @media screen and (max-width: 1199.98px) {
                        font-size: 6rem;
                    }
                }
                .text {
                    color: #FFF;
                    font-size: 2.8rem;
                    font-weight: 400;
                    @media screen and (max-width: 1199.98px) {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}

.font-size-small {
    font-size: @font-size-small;
}
.new-colour-block(@colour) {
    border: 1px solid @colour;
    padding: 0!important;
    .block-heading {
        background-color: @colour;
        color: @white;
        padding: 2.5rem;
        h3 {
            margin: 0!important;
            font-weight: 600!important;
        }
    }
    .block-body {
        background-color: lighten(@colour, 40%);
        padding: 3rem 2.5rem;
        font-size: 1.6rem;
        p, .dropdown, ul {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a, a:hover, a:active {
            text-decoration: underline @colour!important;
            font-weight: 600;
            color: @gray;
        }
        .dropdown, .dropdown.open {
            .btn-default {
                background-color: @colour;
                color: @white!important;
            }
            .dropdown-menu {
                background-color: @colour;
                & > li {
                    & > a {
                        color: @white!important;
                        &:hover, &:active {
                            background-color: darken(@colour, 20%);
                            text-decoration: none!important;
                        }
                    }
                }
            }
        }
        .pdf-links, .video-links {
            padding-left: 0;
            list-style: none;
            li {
                margin-bottom: .5rem;
                svg {
                    width: 20px;
                    height: auto;
                    vertical-align: bottom;
                    margin-right: 1rem;
                    color: @colour;
                }
            }
        }
    }
}
.font-size-small {
    font-size: @font-size-small;
}
