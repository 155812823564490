.filters-groups {

  .filter-control {
    margin-right: 6px;
  }
}


.filter-control {
  display: inline-block;
  padding: 9px 15px;
  font-size: @font-size-small;
  color: #C1C1C1;
  font-weight: 700;
  border: 1px solid #E1E1E1;
  border-radius: @btn-border-radius-base;

  &:focus,
  &:hover {
    color: @text-color;
    text-decoration: none;
  }

  &.active {
    color: @link-color;
    border-color: @link-color;
  }
}