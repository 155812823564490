@import "./fontawesome-pro/solid";
@import "./fontawesome-pro/light";
@import "./fontawesome-pro/regular";
@import "./fontawesome-pro/brands";
@fa-css-prefix: icon;
@import "./fontawesome-pro/fontawesome";

@font-face {
  font-family: 'icomoon';
  src:  url('@{fonts-path}/icons/icomoon.eot?dmzwvq');
  src:  url('@{fonts-path}/icons/icomoon.eot?dmzwvq#iefix') format('embedded-opentype'),
        url('@{fonts-path}/icons/icomoon.ttf?dmzwvq') format('truetype'),
        url('@{fonts-path}/icons/icomoon.woff?dmzwvq') format('woff'),
        url('@{fonts-path}/icons/icomoon.svg?dmzwvq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*=" circle-icon-"],
[class^="circle-icon-"] {
  display: inline-block;
  width: 130px;
  height: 130px;
  background-color: @brand-blue-primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 45%;
  border-radius: 50%;
  transition: all .2s;

  &:focus,
  &:hover {
    background-size: 50%;
  }
}

.circle-icon-patient-form {
  background-image: @icon-patient-form;
}

.circle-icon-patient-info {
  background-image: @icon-patient-info;
}

.circle-icon-children {
  background-image: @icon-children;
}

.circle-icon-pay-bill {
  background-image: @icon-pay-bill;
}

.circle-icon-blood {
  background-image: @icon-blood;
}

.circle-icon-flash {
  background-image: @icon-flash;
}


.icon {
  .fal;
}
.icon-elearning:before {
  content: @fa-var-chalkboard-teacher;
}
.icon-hand2:before {
  content: @fa-var-hand-holding-heart;
}
.icon-hands:before {
  content: @fa-var-handshake;
}
.icon-idea:before {
  content: @fa-var-lightbulb-on;
}
.icon-line:before {
  content: @fa-var-chart-line;
}
//.icon-micro:before {
//  content: "\e938";
//}
.icon-mobile:before {
  content: @fa-var-mobile-alt;
}
.icon-pencil:before {
  content: @fa-var-pencil-alt;
}
//.icon-person:before {
//  content: "\e93b";
//}
.icon-ipc:before {
  content: @fa-var-procedures;
}
.icon-active-man:before {
  content: @fa-var-running;
}
.icon-ambulance:before {
  content: @fa-var-ambulance;
}
.icon-armchair:before {
  content: @fa-var-loveseat;
}
.icon-arrow-left:before {
  content: @fa-var-chevron-left;
}
.icon-arrow-right:before {
  content: @fa-var-chevron-right;
}
.icon-bag:before {
  content: @fa-var-briefcase;
}
.icon-bed:before {
  content: @fa-var-bed;
}
//.icon-bloodclot:before {
//  content: "\e907";
//}
.icon-car:before {
  content: @fa-var-car-side;
}
.icon-cross:before {
  content: @fa-var-times;
}
.icon-drink:before {
  content: @fa-var-glass;
}
.icon-important:before {
  content: @fa-var-hand-pointer;
}
.icon-list:before {
  content: @fa-var-ballot-check;
}
.icon-meal:before {
  content: @fa-var-utensils;
}
.icon-medication:before {
  content: @fa-var-pills;
}
.icon-menu:before {
  content: @fa-var-bars;
}
.icon-pack:before {
  content: @fa-var-suitcase;
}
.icon-parent-child:before {
  content: @fa-var-child;
}
.icon-parking:before {
  .fa;
  content: @fa-var-car;
}
.icon-phone:before {
  content: @fa-var-phone;
}
.icon-play:before {
  content: @fa-var-play-circle;
}
.icon-search:before {
  content: @fa-var-search;
}
.icon-socks:before {
  content: @fa-var-socks;
}
.icon-tablet-menumore:before {
  content: @fa-var-caret-down;
}
.icon-tick:before {
  content: @fa-var-check;
}
.icon-visitor:before {
  content: @fa-var-users;
}
.icon-icon-printer:before {
  content: @fa-var-print;
}
.icon-icon-mail:before {
  content: @fa-var-envelope;
}
.icon-play-strong:before {
  .fa;
  content: @fa-var-play-circle;
}
.icon-bars:before {
  content: @fa-var-bars;
}

.icon-facebook:before {
  .fab;
  content: @fa-var-facebook-square;
}
.icon-linkedin:before {
  .fab;
  content: @fa-var-linkedin;
}
.icon-chevron-right:before {
  .fab;
  content: @fa-var-chevron-right;
}