@blue-bg: #0092D0;

.bg-red-orange {
  background-color: @mandarin;
}
.bg-orange {
  background-color: @orange;
}
.bg-violet {
  background-color: @grape;
}
.bg-violet-20 {
  background-color: fade(@grape, 20%);
}
.bg-md-violet-20 {
  @media screen and (min-width: 768px) {
    background-color: fade(@grape, 20%)!important;
  }
}
.bg-red {
  background-color: @pink;
}
.bg-blue-20 {
  background-color: fade(@blue-bg, 20%);
}
.bg-md-blue-20 {
  @media screen and (min-width: 768px) {
    background-color: fade(@blue-bg, 20%)!important;
  }
}


#patient_info.quality-performance-main {
  border-top: none;
  #qp-tabs {
    @media (max-width: @screen-sm-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .qp-nav {
    @media screen and (min-width: 1200px) {
      width: 280px;
      margin-top: 15px;
    }
    .nav {
      .nav-item {
        .nav-link {
          @media screen and (min-width: 1200px) {
            padding: 10px 45px 10px 0;
          }
          &:after {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            @media screen and (min-width: 1200px) {
              right: 15px;
            }
          }
          &:focus {
            font-weight: 400;
            outline: none;
          }
          &:hover, &:active, &:focus {
            color: @brand-blue-primary;
          }
        }
      }
    }
  }
  .qp-main {
    @media screen and (min-width: 1200px) {
      // width: 890px;
      padding-right: 0;
      padding-top: 15px;
    }
    @media screen and (max-width: @screen-sm-max) {
      padding-top: 0;
    }
    .tab-content {
      @media screen and (min-width: 1200px) {
        margin-left: 15px;
      }
      .tab_title {
        margin-bottom: 0;
        border-bottom: none;
        line-height: 1.3;
        @media screen and (max-width: @screen-xs-max) {
          padding-bottom: 0;
          height: 62px;
          display: table-cell;
          vertical-align: middle;
        }
        @media screen and (max-width: 685px) {
          font-size: 26px;
          line-height: 1.1;
        }
        //br {
        //  @media screen and (max-width: @screen-sm-max) {
        //    display: none;
        //  }
        //}
      }
      .menu_switcher {
        transform: scaleX(-1);
        cursor: pointer;
        @media screen and (max-width: @screen-xs-max) {
          padding: 17px 10px;
        }
      }
      .content-section {
        margin-bottom: 30px;
        position: relative;
        &.first-content-section {
          margin-top: 30px;
          &:before {
            content: "";
            display: block;
            width: 100%;
            border-top: 1px solid @fog;
            position: absolute;
            top: -30px;
            left: 0;
          }
        }
        &.with-bg-image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        h4 {
          font-size: 26px;
          font-weight: 500;
          font-family: @font-family-brand;
          margin-top: 0;
          margin-bottom: 20px;
          @media screen and (max-width: @screen-sm-max) {
            margin-bottom: 15px;
          }
          @media screen and (max-width: @screen-xs-max) {
            font-size: 20px;
          }
        }
        h5 {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    .text-block {
      &.boxed {
        color: @white;
      }
      p {
        margin-bottom: 20px;
        @media screen and (max-width: @screen-sm-max) {
          margin-bottom: 15px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          padding-left: 20px;
          position: relative;
          &:before {
            content: "\2022";
            display: block;
            position: absolute;
            left: 0;
          }
        }
      }
      p + ul {
        margin-top: -20px;
      }
      a {
        color: @brand-blue-primary;
      }
    }
    .text-page-link-block {
      display: block;
      text-decoration: none;
      .page-link-label-wrapper {
        overflow: hidden;
      }
    }
    .text-page-link-block + .contact-modal {
      .modal-header {
        padding: 0;
      }
      .modal-body {
        padding-top: 60px;
      }
      h2 {
        line-height: 1.2;
      }
      .display-message {
        padding-top: 0;
        margin-bottom: 20px;
      }
    }
    .image-page-link-block {
      display: inline-block;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .text-page-link-block, .image-page-link-block {
      &.coming-soon {
        pointer-events: none;
        position: relative;
        .coming-soon-label {
          font-size: 15px;
          color: #252525;
          width: 120px;
          height: 30px;
          background-color: @white;
          font-weight: 400;
          position: absolute;
          text-align: center;
          line-height: 30px;
          margin-bottom: 0;
        }
      }
    }
    .profile-block {
      display: block;
      @media screen and (min-width: @screen-sm) {
        display: flex;
        flex-wrap: wrap;
      }
      .profile-photo {
        margin: 5px 30px 0 0;
        width: auto;
        max-width: 100%;
        margin-bottom: 20px;
        @media screen and (min-width: @screen-sm) {
          max-width: 50%;
          flex: 0 0 50%;
          margin-bottom: 0;
        }
        @media screen and (min-width: @screen-md) {
          max-width: 270px;
          flex: 0 0 270px;
        }
        &.no-profile-image {
          width: 270px;
          height: 313px;
          background-color: @brand-blue-primary;
        }
      }
      .profile-data {
        flex: 1 1 0;
        .profile-job-title, .profile-organisation {
          color: @brand-blue-primary;
        }
        .profile-name {
          margin: 0 0 5px 0;
        }
        .profile-job-title {
          margin-bottom: 0;
        }
        .profile-organisation {
          margin-bottom: 25px;
        }
      }
      &.block-vertical-profile {
        flex-direction: column;
        @media screen and (min-width: @screen-md) {
          align-items: flex-end;
        }
        .profile-photo, .profile-data {
          @media screen and (min-width: 1200px) {
            width: 270px;
          }
        }
        .profile-photo {
          margin: 5px 0 15px 0;
          flex: 1 1 auto;
        }
      }
    }
    .steps-block {
      .step {
        &:before {
          margin-left: -30px;
        }
        img {
          max-width: 100%;
          width: auto;
        }
      }
    }
    .table-block {
      .table {
        font-size: 14px;
        margin-bottom: 0;
        td, th {
          vertical-align: middle;
          padding: 10px 5px;
          @media screen and (min-width: 1200px) {
            padding: 10px 0;
          }
        }
        th {
          color: @brand-blue-primary;
          border-bottom-width: 1px;
        }
        a {
          color: @brand-blue-primary;
          text-decoration: none;
        }
      }
    }
  }
  .menu_shown {
    .qp-main {
      .menu_switcher {
        transform: scaleX(1);
      }
    }
  }

  .section-page-links {
    height: 309px;
    @media screen and (max-width: @screen-xs-max) {
      background-image: none!important;
      height: auto;
      background-color: @fog;
      margin: 0 -15px;
      padding: 15px;
    }
    .col-md-6:first-child {
      @media screen and (max-width: @screen-sm-max) {
        display: none;
      }
    }
    .text-page-link-block {
      color: @white!important;
      height: 103px;
      font-size: 0;
      padding: 0 15px;
      @media screen and (min-width: 1200px) {
        padding: 0 35px;
      }
      @media screen and (max-width: @screen-xs-max) {
        height: 60px;
        border-width: 5px!important;
        margin-bottom: 5px;
        background-color: @white!important;
        position: relative;
      }
      &:first-child {
        border-bottom: 10px solid @orange;
        background-color: fade(@orange, 60%);
        &:hover {
          background-color: fade(@orange, 80%);
        }
        @media screen and (max-width: @screen-xs-max) {
          color: @orange!important;
        }
        .page-link-label {
          &:after {
            @media screen and (max-width: @screen-xs-max) {
              border-color: @orange!important;
            }
          }
        }
        &.coming-soon {
          @media screen and (max-width: @screen-xs-max) {
            border-bottom-color: fade(@orange, 60%);
          }
        }
      }
      &:nth-child(2) {
        border-bottom: 10px solid @mandarin;
        background-color: fade(@mandarin, 60%);
        &:hover {
          background-color: fade(@mandarin, 80%);
        }
        @media screen and (max-width: @screen-xs-max) {
          color: @mandarin!important;
        }
        .page-link-label {
          &:after {
            @media screen and (max-width: @screen-xs-max) {
              border-color: @mandarin!important;
            }
          }
        }
        &.coming-soon {
          @media screen and (max-width: @screen-xs-max) {
            border-bottom-color: fade(@mandarin, 60%);
          }
        }
      }
      &:last-child {
        border-bottom: 10px solid @pink;
        background-color: fade(@pink, 60%);
        &:hover {
          background-color: fade(@pink, 80%);
        }
        @media screen and (max-width: @screen-xs-max) {
          color: @pink!important;
        }
        .page-link-label {
          &:after {
            @media screen and (max-width: @screen-xs-max) {
              border-color: @pink!important;
            }
          }
        }
        &.coming-soon {
          @media screen and (max-width: @screen-xs-max) {
            border-bottom-color: fade(@pink, 60%);
          }
        }
      }
      &.coming-soon {
        .page-link-label-wrapper {
          @media screen and (max-width: @screen-xs-max) {
            opacity: .6;
          }
        }
        .coming-soon-label {
          right: 35px;
          top: 12px;
          @media screen and (min-width: @screen-sm) {
            top: 36.5px;
          }
        }
      }
      & > div {
        display: inline-block;
        vertical-align: middle;
      }
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 103px;
        @media screen and (max-width: @screen-xs-max) {
          height: 50px;
        }
        @media screen and (max-width: 480px) {
          height: 51px;
        }
      }
      .page-link-label {
        margin-bottom: 0!important;
        @media screen and (max-width: @screen-xs-max) {
          font-size: 24px!important;
        }
        @media screen and (max-width: 480px) {
          font-size: 20px!important;
          font-weight: 600!important;
        }
        &:after {
          @media screen and (max-width: @screen-xs-max) {
            position: absolute;
            top: 12px;
            right: 15px;
            content: "";
            border-style: solid;
            border-width: 3px 3px 0 0;
            width: 12px;
            height: 12px;
            display: inline-block;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            margin-top: 7px;
          }
        }
      }
      .page-link-content {
        font-size: 16px;
        @media screen and (max-width: @screen-xs-max) {
          display: none;
        }
      }
    }
  }

  #section-steps {
    background-color: fade(@blue-bg, 20%);
    padding: 35px 15px 15px 15px;
    h4 {
      color: @brand-blue-primary;
      padding-left: 20px;
      margin-bottom: 25px;
    }
    .two-column-steps {
      background-color: @white;
      padding: 30px 0;
      margin-bottom: 20px;
      .steps {
        @media screen and (min-width: 1200px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          padding: 0;
          grid-template-areas:
                  "a b"
                  "a c";
        }
        .step {
          margin-top: 0;
          @media screen and (min-width: 1200px) {
            padding-left: 40px;
          }
          &:first-child {
            grid-area: a;
          }
          &:nth-child(2) {
            grid-area: b;
          }
          &:last-child {
            grid-area: c;
          }
        }
      }
    }
  }

  #section-measure {
    .col-xs-12 {
      float: none;
      .text-block {
        margin-bottom: 20px;
      }
    }
    .col-md-6 {
      .boxed {
        height: 250px;
        text-align: center;
        padding: 15px;
        font-size: 0;
        margin-bottom: 15px;
        & > div {
          display: inline-block;
          font-size: 24px;
          font-weight: 600;
          font-family: @font-family-brand;
          vertical-align: middle;
          line-height: 26px;
          width: 100%;
          @media screen and (min-width: @screen-md) {
            font-size: 30px;
            line-height: 40px;
          }
          p {
            margin-bottom: 0;
          }
        }
        &:after {
          content: "";
          vertical-align: middle;
          height: 100%;
          display: inline-block;
        }
        &.bg-red-orange {
          & > div {
            @media screen and (min-width: 1200px) {
              max-width: 325px;
            }
          }
        }
        &.bg-violet {
          & > div {
            @media screen and (min-width: 1200px) {
              max-width: 335px;
            }
          }
        }
        &.bg-red {
          & > div {
            @media screen and (min-width: 1200px) {
              max-width: 345px;
            }
          }
        }
        &.bg-orange {
          & > div {
            @media screen and (min-width: @screen-md) {
              font-size: 24px;
              line-height: 26px;
            }
            @media screen and (min-width: 1200px) {
              font-size: 30px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  #section-co-design-project {
    margin-bottom: 20px;
    & > .row {
      @media screen and (min-width: @screen-md) {
        display: flex;
        align-items: stretch;
      }
    }
    .text-page-link-block {
      padding: 30px;
      height: 100%;
      .page-link-label {
        position: relative;
        color: @white;
        margin-top: 0;
        margin-bottom: 20px;
        padding-right: 20px;
        &:after {            
          content: "\f054";
          font-family: "Font Awesome 5 Pro";
          -webkit-font-smoothing: antialiased;
          position: absolute;
          right: 0;
          top: 0;
          color: @white;
        }
      }
      .page-link-content {
        color: @white;
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.coming-soon {
        .coming-soon-label {
          display: none;
        }
      }
    }
  }

  #section-case-study {
    .col-md-6 {
      &:first-child {
        @media screen and (min-width: @screen-md) {
          width: 58.14%;
        }
      }
      &:last-child {
        @media screen and (min-width: @screen-md) {
          width: 41.86%;
        }
      }
    }
    .boxed {
      height: 190px;
      padding: 30px;
      font-size: 0;
      & > div {
        display: inline-block;
        vertical-align: middle;
        h4 {
          font-size: 26px;
          font-weight: 500;
          font-family: @font-family-brand;
          @media screen and (max-width: @screen-xs-max) {
            font-size: 20px;
          }
        }
        p {
          font-size: 16px;
        }
        a {
          color: @white;
        }
      }
      &:after {
        content: "";
        vertical-align: middle;
        height: 100%;
        display: inline-block;
      }
    }
  }

  #section-working-with-patients {

    .text-block {
      @media screen and (min-width: @screen-md ) {
        width: ~"calc(100% - 270px)";
        padding-right: 30px;
        float: left;
      }
    }
    .profile-block {
      @media screen and (min-width: @screen-md ) {
        width: 270px;
        float: right;
      }
    }
    &:first-child {

    }
    &:nth-child(2) {

    }
    &:last-child {

    }

  }

  #block-team-members {
    @media screen and (max-width: @screen-sm-max) {
      border: none;
      margin-bottom: 0;
    }
    .table {
      @media screen and (max-width: @screen-sm-max) {
        display: block;
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid @fog;
            margin-bottom: 20px;
            padding: 15px;
            td {
              border: none;
              padding: 0;
              &:first-child {
                font-size: 20px;
                font-weight: 600;
                order: 1;
              }
              &:nth-child(2) {
                order: 3;
              }
              &:nth-child(2), &:last-child {
                flex: 0 0 100%;
              }
              &:nth-child(3) {
                order: 2;
                flex: 0 0 20px;
                a {
                  font-size: 0;
                  &:before {
                    margin-left: 15px;
                    display: block;
                    line-height: 28px;
                    font-size: 18px;
                    content: "\e91c";
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                  }
                }
              }
              &:last-child {
                order: 4;
              }
            }
          }
        }
      }
    }
  }
}

#quality-performance-patient-experience-story {
  .fit_block {
    align-items: flex-start;
    & > img {
      width: 270px;
      flex: 0 0 270px;
      margin-top: 15px;
      max-width: 270px;
    }
    .text_part {
      width: auto;
      flex: 1 1 auto;
      h4 {
        font-size: 26px;
        font-weight: 500;
        font-family: @font-family-brand;
        @media screen and (max-width: @screen-xs-max) {
          font-size: 20px;
        }
      }
      h4, p {
        margin-bottom: 20px;
      }
    }
  }
}

.no-cssgrid {
  #patient_info.quality-performance-main {
    .qp-main {
      #section-steps {
        .two-column-steps {
          .steps {
            overflow: hidden;
            @media screen and (min-width: 1200px) {
              display: block;
            }
            .step {
              @media screen and (min-width: @screen-md) {
                float: left;
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

.page-masthead.content-page {
  .card {
    .card-body {

    }
  }
}

#quality-performance-intro {
  margin-bottom: 30px;
  h4 {
    font-size: 26px;
    font-weight: 500;
    font-family: @font-family-brand;
    letter-spacing: normal;
    @media screen and (max-width: @screen-xs-max) {
      font-size: 20px;
    }
    br {
      @media screen and (min-width: 1200px) {
        display: inline;
      }
    }
  }
  p {
    margin-bottom: 20px;
  }
}

.column-page-link-blocks {
  .image-page-link-block {
    &.coming-soon {
      & > div {
        img {
          opacity: .6;
        }
      }
      .coming-soon-label {
        top: 16px;
        right: 13px;
      }
    }
  }
  .text-page-link-block {
    &.coming-soon {
      & > div {
        opacity: .6;
      }
      .coming-soon-label {
        display: none;
      }
    }
  }
  &.clear-left {
    clear: left;
  }
}


