.profile-home-login {
  padding: 10px 12px;
  border: 1px solid @brand-blue-primary-light;
  border-radius: @border-radius-large;

  .icon-plus {
    display: inline-block;
    margin-top: -4px;
    margin-right: 4px;
    font-size: 20px;
    vertical-align: middle;
  }

  a& {
    text-decoration: none;
  }
}