select.form-control, .select2-container--default .select2-selection--single {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: @icon-arrow-select;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  .font_brand;
  font-weight: 500;
  color: @grey_2;
  background-color: @white;
  .select2-selection__arrow b {
    display: none;
  }
  &.with_search_icon {
    background-image: none;
  }
}

select::-ms-expand {
  display: none;
}

.form-container {
  padding: 36px;
  background-color: @brand-gray-light;
}


.form-heading {
  &:extend(.h3);
  margin: 0 0 36px;
}


.form-group {
  &:extend(.form-control);
  position: relative;
  height: auto;
  padding: 0;
  overflow: hidden;

  .control-label {
    display: block;
    margin: 0;
    padding: 10px;
    font-weight: 400;
  }

  .form-control {
    height: @line-height-computed + 20;
    padding: 10px;
    font-weight: 700;
    color: @brand-blue-primary;
    border: 0;
    background-color: transparent;

    &,
    &:focus,
    &:hover {
      box-shadow: none;
    }

    &::-moz-placeholder {
      opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }

    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      font-weight: 400 !important;
    }
  }

  textarea.form-control {
    height: auto;
  }

  &.optional {
    &:after {
      content: attr(data-option);
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: @font-size-small;
      font-style: italic;
      color: @text-muted;
    }
  }
}


.form-group-check {
  margin-bottom: 20px;

  .custom-control {
    display: block;

    & + .custom-control {
      margin-top: 10px;
    }
  }
}


.form-group-check-inline {
  margin-bottom: 20px;

  .custom-control {
    margin-right: 10px;
  }
}





//
// Custom radio & checkbox
//

.custom-control {
  position: relative;
  padding-left: 30px;
  font-weight: 400;

  .user-select(none);
}


.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  .custom-radio &:checked ~ .custom-control-indicator {
    border: 3px solid @brand-blue-primary;
    background: @icon-radio no-repeat center;
  }

  .custom-checkbox &:checked ~ .custom-control-indicator {
    border: 3px solid @brand-blue-primary;
    background: @brand-blue-primary @icon-checkbox no-repeat center;
  }

  &:checked ~ .custom-control-text {
    font-weight: 700;
    color: @brand-blue-primary;
  }
}

.custom-control-indicator {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-left: -30px;
  border: 1px solid @input-border;
  background-color: #FFF;
  transition: border .1s;

  .custom-checkbox & {
    border-radius: 3px;
  }

  .custom-radio & {
    border-radius: 50%;
  }
}