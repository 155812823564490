a {
  transition: color .1s;
}

.southercross-logo {
  display: block;
  width: @southerncross-logo-width;
  height: @southerncross-logo-height;
  font: 0/0 a;
  background: url(@southerncross-logo) no-repeat;
  background-size: contain;

  @media (max-width: @screen-xs-max) {
    width: @southerncross-logo-width-sm;
    height: @southerncross-logo-height-sm;
  }
  @media screen and (max-width: 380px) {
    height: 4rem;
  }
}

h1, h2, h4{
  font-family: @font-family-brand !important;
}

h1, .h1, h2, .h2, .h3 {
  font-weight: 600;
}

h2, .h2 {
  font-size: 4rem;
  line-height: 1;
  @media screen and (max-width: 767.8px) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

h3, .h3 {
  font-family: @font-family-base;
  font-weight: 700;
}

h4, .h4{
  font-weight: 400;
}
h5, .h5{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}
p {
  &:last-child {
    margin-bottom: 0;
  }
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.left-0{ left: 0; }
.top-0{ top: 0; }
.w-full{ width: 100%; }
.h-full{ height: 100%; }

.align-items-end {
  align-items: flex-end;
}

.blue-overlay{ 
  background: @brand-blue-primary;
  opacity: .3;
}
.inline-block {display: inline-block;}


.quick-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 450px;
  padding: 15px;
  background-color: @orange;

  .control-label {
    margin: 0 15px 0 0;
    color: #FFF;
    font-weight: 400;
    white-space: nowrap;
  }

  .form-control {
    height: 48px;
    padding-right: 50px;
    border-color: transparent;
  }

  .btn {
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    height: auto;
    color: #B0B0B0;
    border-color: transparent;
    background: transparent;

    .icon-search {
      display: block;
    }
  }
}



ol:not(.list-unstyled):not(.breadcrumb) {
  margin: 0;
  padding: 0 0 0 40px;
  list-style: none;
  counter-reset: counter;
  &.four {
    counter-reset: counter 3;
  }
  &.five {
    counter-reset: counter 4;
  }
  & > li {
    & + li {
      margin-top: 20px;
    }

    &:before {
      content: counter(counter);
      counter-increment: counter;
      width: 22px;
      height: 22px;
      float: left;
      margin-left: -40px;
      color: #FFF;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      background-color: @gray;
      border-radius: 50%;
    }
  }
}

ol.ordered-list {
  li {
    list-style-type: decimal;
    &:before {
      display: none;
    }
  }
  &.lower-alpha {
    li {
      list-style-type: lower-alpha;
    }
  }
  &.upper-alpha {
    li {
      list-style-type: upper-alpha;
    }
  }
}


.paragraph-group {
  p {
    margin: 30px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}


.text-2-columns {
  column-count: 2;
  column-gap: 30px;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
  & > div[class^=col-] {
    padding-left: 0;
    padding-right: 0;
  }
}

.half-gutter {
  margin-left: -7.5px;
  margin-right: -7.5px;
  & > div[class^=col-] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.bg-transparent {
  .bg-colour(transparent);
}

.bg-blue {
  .bg-colour(@brand-blue-primary);
}

.bg-yellow {
  .bg-colour(@yellow);
}

.bg-orange {
  .bg-colour(@orange);
  .colour(@white);
}

.bg-mandarin {
  .bg-colour(@mandarin);
  .colour(@white);
}

.bg-pink {
  .bg-colour(@pink);
  .colour(@white);
}

.bg-grape {
  .bg-colour(@grape);
  .colour(@white);
}

.bg-teal {
  .bg-colour(@teal);
  .colour(@white);
}

.bg-green {
  .bg-colour(@green);
  .colour(@white);
}

.bg-gray {
  .bg-colour(@gray);
  .colour(@white);
}

.bg-gray-light {
  .bg-colour(@gray-light);
}

.bg-navy {
  .bg-colour(@navy);
}

.bg-position-right {
  background-position-x: right;
}

.bg-position-md-left {
  @media screen and (min-width: 768px) {
    background-position-x: left;
  }
}

.bg-position-md-center {
  @media screen and (min-width: 768px) {
    background-position-x: center;
  }
}

.text-white {
  .colour(@white);
}

.text-blue {
  .colour(@brand-blue-primary);
}

.text-yellow {
  .colour(@yellow);
}

.text-orange {
  .colour(@orange);
}
a.text-orange {
  .colour(@orange);
  &:hover, &:active {
    .colour(@orange);
  }
}

.text-mandarin {
  .colour(@mandarin);
}

.text-pink {
  .colour(@pink);
}

.text-grape {
  .colour(@grape);
}

.text-teal {
  .colour(@teal);
}

.text-green {
  .colour(@green);
}

.fill-orange {
  fill: @orange;
}

.fill-mandarin {
  fill: @mandarin;
}

.fill-pink {
  fill: @pink;
}

.fill-grape {
  fill: @grape;
}
.fill-teal {
  fill: @teal;
}

.btn-primary {
  &:active, &:hover, &:focus {
    .bg-colour(@brand-blue-primary-light);
  }
}

.pagination {
  [class^=icon-] {
    font-weight: 400;
    font-size: 15px;
  }
  & > .first, & > .prev, & > .next, & > .last {
    & > a {
      padding-top: 6px;
      padding-bottom: 4px;
    }
  }
  & > .page-item {
    & > a {
      color: @brand-blue-primary;
      background-color: @white;
      &:hover, &:active {
        color: @brand-blue-primary-light;
        background-color: @white;
      }
    }
  }
  & > .disabled {
    & > a {
      color: @gray;
      &:hover, &:active {
        color: @gray;
      }
    }
  }
  & > .active {
    & > a {
      color: @white;
      background-color: @brand-blue-primary;
      border-color: @brand-blue-primary;
      &:hover, &:active {
        color: @white;
        background-color: @brand-blue-primary;
        border-color: @brand-blue-primary;
      }
    }
  }
}
.font-slim{ font-weight: 200 !important; }
.font-normal{ font-weight: 400 !important; }
.font-semibold{ font-weight: 600 !important; }
.font-bold{ font-weight: 700 !important; }
.mw-100 {
  max-width: 100%!important;
}

.block-teal {
  .new-colour-block(@teal);
}
.block-blue {
  .new-colour-block(@brand-blue-primary-light);
}
.block-mandarin {
  .new-colour-block(@mandarin);
}

.float-right {
  float: right;
}
.float-md-right {
  @media screen and (min-width: 768px) {
    float: right;    
  }
}
.float-lg-right {
  @media screen and (min-width: 1200px) {
    float: right;    
  }
}
.float-left {
  float: left;
}
.float-md-left {
  @media screen and (min-width: 768px) {
    float: left;
  }
}
.mw-200 {
  max-width: 20rem;
  width: 100%;
}
.mw-300 {
  max-width: 30rem;
  width: 100%;
}
.mw-400 {
  max-width: 40rem;
  width: 100%;
}
.mw-500 {
  max-width: 50rem;
  width: 100%;
}
.mw-600 {
  max-width: 60rem;
  width: 100%;
}
.mw-700 {
  max-width: 70rem;
  width: 100%;
}
.mw-lg-280 {
  @media screen and (min-width: 1200px) {
    max-width: 28rem;
    width: 100%;
  }
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-md-absolute {
  @media screen and (min-width: 768px) {
    position: absolute;
  }
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.z-index-1 {
  z-index: 1;
}
.object-fit-cover {
  object-fit: cover;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-start {
  justify-content: flex-start!important;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-md-1 {
  @media screen and (min-width: 768px) {
    order: 1;
  }
}
.order-md-2 {
  @media screen and (min-width: 768px) {
    order: 2;
  }
}
.order-lg-1 {
  @media screen and (min-width: 992px) {
    order: 1;
  }
}
.order-lg-2 {
  @media screen and (min-width: 992px) {
    order: 2;
  }
}
.order-3 {
  order: 3;
}
.order-lg-3 {
  @media screen and (min-width: 992px) {
    order: 3;
  }
}

.font-size-20 {
  font-size: 2rem;
}
.mr-2rem {
  margin-right: 2rem;
}

.border-1px-blue {
  border: 1px solid @brand-blue-primary;
}
.gap-20 {
  gap: 2rem;
}
.gap-30 {
  gap: 3rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-md-25 {
  @media screen and (min-width: 768px) {
    margin-bottom: 2.5rem;
  }
}
.d-none {
  display: none;
}
.d-md-block {
  @media screen and (min-width: 768px) {
    display: block;
  }
}
.d-md-none {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.d-md-inline {
  @media screen and (min-width: 768px) {
    display: inline;
  }
}
.keyline {
  border: 1px solid @orange;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  @media screen and (min-width: 768px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.visibility-hidden {
  visibility: hidden;
}
.m-0 {
  margin: 0!important;
}
.flex-1-1-0 {
  flex: 1 1 0;
}

.flex-md-1-1-0 {
  @media screen and (min-width: 768px) {
    flex: 1 1 0;
  }
}

.flex-md-0-0-50 {
  @media screen and (min-width: 768px) {
    flex: 1 1 50%;
  }
}

h1, .h1 {
  margin-top: 0;
  @media (max-width: @screen-sm-max) {
    font-size: @font-size-h1-sm-max;
  }
}

figure {
  img {
    width: 100%;
    max-width: 100%;
  }
  figcaption {
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 0.5rem;
    color: @black;
  }
}

.figcaption {
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 0.5rem;
  color: @black;
}

.ratio {
  position: relative;
  width: 100%;
  &:before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.w-md-50p {
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}